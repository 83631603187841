<template>
  <section class="demand-list">
    <a-list size="small" bordered :data-source="demandList" :pagination="demandList.length > 0 ? pagination : false"
      id="demand-list-content" :class="[isContainConsult == true ? 'demand-list-content' : 'not-demand-list-content' ]">
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item ant-list-item-no-flex">
          <a-row class="padding-container">
            <a-col :span="22">
              <a-row>
                <a-col :span="10" style="padding-right: 3vw">
                  <p class="first-line demand-name">
                    <span class="num" @click="checkDemandDetails(item)">{{ item.demandNumber }}</span>
                    <Tooltip :title=item.demandName>
                      <span class="demand-name-text" @click="checkDemandDetails(item)">{{ item.demandName }}</span>
                    </Tooltip>
                    <a-tag color="blue" v-show="item.completeChannel" class="completetag">
                      {{ item.completeChannel }}
                    </a-tag>
                  </p>
                  <ul class="key-msg-list sec-line">
                    <!--                    <li class="key-msg-item">-->
                    <!--                      <ApartmentOutlined class="icon" />-->
                    <!--                      {{ item.demandPosition }}-->
                    <!--                    </li>-->
                    <li class="key-msg-item">
                      <Tooltip title="人员级别">
                        <img src="@/assets/iconfont/level.png" class="icon img" />
                      </Tooltip>
                      {{ item.staffGrade }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="预计用人时长">
                        <ClockCircleOutlined class="icon" />
                      </Tooltip>
                      {{ item.useStaffLength }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="到岗时间">
                        <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img" />
                      </Tooltip>
                      {{ item.expectArrivalTime }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="工作地点">
                        <EnvironmentOutlined class="icon" />
                      </Tooltip>
                      {{ item.workLocation }}
                    </li>
                  </ul>
                </a-col>
                <a-col :span="6">
                  <p class="first-line">
                    <Tooltip :title=item.projectName>
                      项目名称：{{ item.projectName }}
                    </Tooltip>
                  </p>
                  <ul class="key-msg-list sec-line" style="color: #3182CE;margin-left: 0.2vw">
                    <li class="key-msg-item">
                      <Tooltip title="优先级">
                        <OrderedListOutlined class="icon" />
                      </Tooltip>
                      <i class="highest">{{ item.priority }}</i>
                    </li>
                    <!--                    <li class="key-msg-item">-->
                    <!--                      <ForkOutlined class="icon" />-->
                    <!--                      完成渠道：<i class="highest">{{-->
                    <!--                        item.completeChannel-->
                    <!--                      }}</i>-->
                    <!--                    </li>-->
                  </ul>
                </a-col>
                <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                  <p class="first-line">
                    <Tooltip :title=item.customerNameDetail>
                      详细客户名称：{{ item.customerNameDetail }}
                    </Tooltip>
                  </p>
                  <p class="sec-line" style="color: #3182CE">
                    <Tooltip title="客户服务经理(CSM)">
                      <UserOutlined class="icon" />
                    </Tooltip>
                    <Tooltip :title="item.csm">
                      {{ item.csm.length > 50 ? item.csm.slice(0, 40) + '......' : item.csm }}
                    </Tooltip>
                  </p>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="2">
              <div class="add-btn" @click="add(item)" v-show="item.id !== activeRow.id && ![0, 1].includes(nowStep)">
              </div>
              <div class="added-btn" @click="removeAdded(item)"
                v-show="item.id === activeRow.id && ![0, 1].includes(nowStep)">
                <CheckOutlined style="color: white; font-size: 14px; margin-top: 0.2vw;" />
              </div>
            </a-col>
          </a-row>
          <div class="Project-Supplementary-Description">
            <div class="more-info" @click.stop="handleMoreInfoClick(index)">
              <DownCircleFilled class="more-info-icon" :rotate="index == activedItemIndex ? 180 : 0" />
              项目补充说明
            </div>
            <p :class="[
              'suplement',
              index == activedItemIndex ? 'active' : 'un-active',
            ]">
              <span>
                {{ item.projectSupplement ? item.projectSupplement : "无" }}
              </span>
            </p>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </section>
  <DemandInfo ref="demandInfoRef" :isContainConsult="isContainConsult" :nowStep="nowStep" :consultObj="consultObj">
  </DemandInfo>
  <ModalBox :class="iconRed ? 'icon-red' : ''" v-model="modelValue" :ok="handleOk" :content="content"
    :showIconCom="showIconCom" :footer="footer" />
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  onActivated,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  OrderedListOutlined,
  UserOutlined,
  DownCircleFilled,
  CheckOutlined,
  // ForkOutlined,
} from "@ant-design/icons-vue";
import DemandInfo from "./DemandInfo.vue";
import ModalBox from '@/components/ModalBox'
import Tooltip from "@/components/Tooltip.vue";
import { message } from "ant-design-vue";
let queryCache = null;
export default defineComponent({
  name: "DemandList",
  components: {
    Tooltip,
    EnvironmentOutlined,
    ClockCircleOutlined,
    OrderedListOutlined,
    UserOutlined,
    DownCircleFilled,
    DemandInfo,
    CheckOutlined,
    // ForkOutlined,
    ModalBox,
  },
  props: {
    contentStyleObj: Object,
    nowStep: {
      type: Number
    },
    consultObj: Array,
    isContainConsult: {
      type: Boolean,
      default: true
    }
  },
  setup(props,ctx) {
    const iconRed = ref(false)
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const demandInfoRef = ref();
    const demandList = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        getDemandListData(queryCache);
        // 回到页面顶端
        document.getElementById("demand-list-content").parentElement.scrollTop = 0
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        getDemandListData(queryCache);
      },
    });

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.value.current = 1;
      }
    };

    // 获取列表数据
    const getDemandListData = (queryData) => {
      queryCache = queryData ? queryData : {};
      const data = Object.assign(
        { ...queryData, type: '0' },
        {
          pageSize: pagination.value.pageSize,
          pageIndex: pagination.value.current,

        }
      );
      $api.searchDemandByCondition(data).then((res) => {
        demandList.value = res.data.resource.map(item => {
          return {
            ...item,
            csm: item.csm?.substring(0, item.csm?.indexOf('(')) + ' ' + item.csm?.substring(item.csm?.indexOf('('))
          }
        });
        pagination.value.total = res.data.total;
      });
    };
    // 监听项目补充说明点击
    let activedItemIndex = ref(-1);
    const handleMoreInfoClick = (index) => {
      if (activedItemIndex.value === index) {
        activedItemIndex.value = -1;
      } else {
        activedItemIndex.value = index;
      }
    };
    // reset项目补充说明
    const resetActiveItem = () => {
      activedItemIndex.value = -1;
    };
    // 查看项目详情
    const checkDemandDetails = (details) => {
      demandInfoRef.value.showModal(details);
    };
    onMounted(() => {
      resetCurrent();
      document.addEventListener("click", resetActiveItem);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", resetActiveItem);
    });
    onActivated(() => {
      getDemandListData(queryCache);
    });

    const activeRow = ref({});
    const addFunc = (data) => {
      activeRow.value = data;
      if (props.isContainConsult) {
        $EventBus.emit("matchDemand", data)
      } else {
        ctx.emit('changeDemadnd',[data])
      }
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOk = () => {
      modalData.value ? addFunc(modalData.value) : '';
    }
    const add = async (data) => {
      // 新需求匹配添加
      if (props.isContainConsult) {
        let obj = {
          userId: props.consultObj.map((item) => { return item.staffId }),
          demandId: data.id,
        }

        if (props.consultObj.length > 1 && data.completeChannel === "TA_Team") {
          message.error("TA_Team的渠道的需求只允许匹配一个顾问！");
          return
        }

        let res = await $api.isConsultInDemand(obj)
        if (res.msg != '成功') {
          modalData.value = data
          content.value = `顾问${res.data.join('、')}已匹配过该需求，不可重复匹配`
          showIconCom.value = 'ExclamationCircleOutlined'
          footer.value = true
          modelValue.value = true
          iconRed.value = true
          return
        }
      }
      // 下面是原来的逻辑
      const params = {
        demandId: data.id,
      };
      if (data.completeChannel === "TA_Team") {
        $api.isTADemandMatched(params).then((res) => {
          if (res.data) {
            content.value = '本需求（完成渠道：TA_Team）已有选定人员'
            showIconCom.value = 'ExclamationCircleOutlined'
            footer.value = true
            modelValue.value = true
            iconRed.value = true
            /*Modal.info({
              title: "本需求（完成渠道：TA_Team）已有选定人员",
              icon: createVNode(ExclamationCircleOutlined),
              okText: "确定",
              centered: true,
              closable: true,
            });*/
          } else {
            addFunc(data);
          }
        });
      } else {
        $api.isDemandMatched(params).then((res) => {
          if (res.data) {
            modalData.value = data
            content.value = '本需求PM已有选定人员并填写完调令，是否继续本条匹配？'
            showIconCom.value = 'ExclamationCircleOutlined'
            footer.value = false
            modelValue.value = true
            /*Modal.confirm({
              title: "本需求PM已有选定人员并填写完调令，是否继续本条匹配？",
              icon: createVNode(ExclamationCircleOutlined),
              okText: "确定",
              cancelText: "取消",
              onOk() {
                addFunc(data);
              },
              centered: true,
              closable: true,
            });*/
          } else {
            addFunc(data);
          }
        });
      }
    };

    const removeAdded = () => {
      activeRow.value = {};
      if (props.isContainConsult) {
        $EventBus.emit("matchDemand", {})
      } else {
        ctx.emit('changeDemadnd',[])
      }
    };
    $EventBus.on("matchDemand", (data) => {
      activeRow.value = data;
    });
    const resetMatch = () => {
      activeRow.value = {};
      pagination.value.current = 1;
    };

    return {
      demandList,
      pagination,
      activedItemIndex,
      demandInfoRef,
      resetCurrent,
      getDemandListData,
      handleMoreInfoClick,
      checkDemandDetails,
      add,
      removeAdded,
      activeRow,
      resetMatch,
      modelValue,
      content,
      showIconCom,
      iconRed,
      footer,
      handleOk,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
:deep(.ant-list-items) {
  //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  //overflow: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-list-empty-text) {
  //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  width: 100%;
  display: table;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.demand-list {
  overflow-y: auto;
  height: 100% !important;
  .demand-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 17vw);
      overflow-y: auto;
    }
  }
  .not-demand-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 35vh);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    padding: 24px 32px 0;
  }

  .first-line {
    font-size: 18px;
    //color: #1890ff;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .demand-name {
    display: flex;

    .demand-name-text {
      //display: block;
      //overflow: hidden;
      //text-overflow: ellipsis;
      cursor: pointer;
      display: inline-block;
      margin-left: 0.41667vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //line-height: 1.30208vw;
      font-size: .9375vw;
      color: #4d4d4d;
      font-weight: 600;
      border-bottom: 1px solid #4d4d4d;
    }

    .completetag {
      padding-top: 0.15vw;
      font-size: 0.73vw;
    }

    .ant-tag {
      margin-left: 0.6vw;
      border-radius: 1vw;
      line-height: inherit;
    }

    .num {
      display: inline-block;
      padding: 0 4px;
      //line-height: 20px;
      color: #3182CE;
      font-weight: 600;
      border: 1px solid #77CAED;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.8vw;
      margin-top: 0.2vw;
    }

    :deep(.ant-tag-blue) {
      color: #3182CE;
      background: #E9F6FE;
      border-color: #E9F6FE;
    }

  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .more-info {
    margin: 0 32px 16px;
    display: flex;
    align-items: center;
    width: 100px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .suplement {
    line-height: 32px;
    background: #FAFAFA;
    padding: 0 32px;
    margin-bottom: 0;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: 0s;
      border-top: 1px solid #CCCCCC;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 0.05vw;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }

      .img {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .added-btn {
    background-color: #1890ff;
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }
}

:deep(.ant-list-bordered) {
  border: 0px;
}

.icon-red {
  :deep(.ant-modal-body .anticon-exclamation-circle) {
    color: #DC5E4F;
  }
}

:deep(.ant-pagination) {
  justify-content: flex-end;
}

:deep(.ant-spin-nested-loading) {
  //height: calc(100vh - 17vw);
  //overflow-y: auto;
}
</style>
