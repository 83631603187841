<template>
  <section class="top-filter">
    <FilterLayout
        @doReset="resetForm"
        @doSearch="handleFormChange"
        ref="filterLayoutRef"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList"
    >
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <template v-for="(item,index) in queryList" :key="index">
            <template v-if="index<=4">
              <a-form-item :label="item.code" :name="item.name" class="rms-form-item">
                <template v-if="item.type==='2'">
                  <a-select
                      v-model:value="formState[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                  >
                  </a-select>
                </template>
                <template v-if="item.type==='3'">
                  <a-input
                      v-model:value="formState[item.inputDataType]"
                      :placeholder="item.inputDataType==='condition'?'Opportunity Name/PPL ID/THOR ID':'Name/GGID'"
                      allowClear
                      autocomplete="off"
                  />
                </template>
                <template
                    v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['subPl', 'account'].includes(item.inputDataType)">
                  <SelectWithAll :maxTagCount="2" v-model:value="formState[item.inputDataType]"
                                 placeholder="请选择" mode="multiple" showArrow allowClear
                                 :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                  </SelectWithAll>
                </template>
                <template
                    v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['cst', 'offering'].includes(item.inputDataType)">
                  <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                 mode="multiple" showArrow :maxTagCount="2" allowClear
                                 :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                  </SelectWithAll>
                </template>
                <template v-if="item.type === '5'&& item.inputDataType === 'biddingStatus'">
                  <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                                 showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)">
                  </SelectWithAll>
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index>4">
                <a-form-item :label="item.code" :name="item.name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="item.inputDataType==='condition'?'Opportunity Name/PPL ID/THOR ID':'Name/GGID'"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template
                      v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['subPl', 'account'].includes(item.inputDataType)">
                    <SelectWithAll :maxTagCount="2" v-model:value="formState[item.inputDataType]"
                                   placeholder="请选择" mode="multiple" showArrow allowClear
                                   :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                    </SelectWithAll>
                  </template>
                  <template
                      v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['cst', 'offering'].includes(item.inputDataType)">
                    <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                   mode="multiple" showArrow :maxTagCount="2" allowClear
                                   :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type === '5'&& item.inputDataType === 'biddingStatus'">
                    <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                                   showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)">
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
    </FilterLayout>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>

<script>
import FormModalBox from "@/components/FormModalBox.vue";
import MoreFormList from "@/components/MoreFormList.vue";
import {defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {dictionaryOpt} from "@/utils/common";
import {cloneDeep} from "lodash";

export default defineComponent({
  name: "TopFilterPipeline",
  components: {MoreFormList,FormModalBox},
  emits: ["search", "getRowHeight"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const show = ref(false)
    const biddingStatusOps = ref([]);
    const isFollowUpOps = ref([
      {value:'1',label:'Y'},
      {value:'0',label:'N'},
    ])

    let initData = {
      condition: '',
      subPl: [],
      biddingStatus: [],
      account: [],
      cst: [],
      offering: [],
      salescCsmSpoc: '',
      isFollowUp: undefined
    }

    let formState = reactive({ ...initData })

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = cloneDeep(formState);
    }

    const paramListObj = reactive({
      accountOps: [],
      subPlOps: [],
      cstOps: [],
      offeringOps: []
    })

    // 获取筛选框字典
    const getSelectOptions = async () => {
      const params = {
        tag: ['Account', 'SubPL', 'Offering', 'Cst', 'BiddingStatus'],
        offering: '', sub: '',
        account: '',
        subDouble: [], offeringDouble: [],
        accountDouble: [],
        cstDouble: []
      }
      let res = await $api.returnEnum(params)
      paramListObj.accountOps = dictionaryOpt(res.data.account)
      paramListObj.subPlOps = dictionaryOpt(res.data.SubPL)
      paramListObj.cstOps = dictionaryOpt(res.data.allCst)
      paramListObj.offeringOps = dictionaryOpt(res.data.offering)
      biddingStatusOps.value = dictionaryOpt(res.data.BiddingStatus?.filter(item => item !== 'Lost'))
      orgAllOps.value = res.data
    };
    const orgAllOps = ref()

    const resetForm = () => {
      Object.assign(formState, initData)
      formRef.value.resetFields();
      updateParams()
      paramListObj.accountOps = dictionaryOpt(orgAllOps.value.account)
      paramListObj.subPlOps = dictionaryOpt(orgAllOps.value.SubPL)
      paramListObj.cstOps = dictionaryOpt(orgAllOps.value.allCst)
      paramListObj.offeringOps = dictionaryOpt(orgAllOps.value.offering)
      emit("search", currentParams);
    };

    const handleFormChange = () => {
      updateParams()
      emit("search", currentParams);
    };
    const handleFilter = () => {
      show.value = !show.value
      nextTick(() => {
        emit("getRowHeight")
      })
    };
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'resourceManagement',
        secondMenu: 'pipelineMatching',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'account':
          return paramListObj.accountOps
        case 'subPl':
          return paramListObj.subPlOps
        case 'cst':
          return paramListObj.cstOps
        case 'offering':
          return paramListObj.offeringOps
        case 'biddingStatus':
          return biddingStatusOps.value
        case 'isFollowUp':
          return isFollowUpOps.value
        default :
          ''
      }
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        formState.positionId = undefined
        formState.lineManager = ''
        formState.reviewTime = []
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }

    const changeSelectOptions = async(value,type,options) => {
      let res = await $api.returnEnum(value)
      let result = res?.data[type]
      paramListObj[options] = dictionaryOpt(result)
      // 下面的代码是筛选，因为account对应subPL，所以先选subPL的时候会清掉不符合的account，反之亦然
      if(['accountBySub','account'].includes(type)){
        formState.account = formState.account.filter((item)=>{return result.includes(item)})
      } else if(['SubPLByAccount','SubPL'].includes(type)){
        formState.subPl = []
      } else if(['offeringByCstDouble','offering'].includes(type)){
        formState.offering = formState.offering.filter((item)=>{return result.includes(item)})
      } else if(['cst','allCst'].includes(type)){
        formState.cst = []
      }
    }
    const getPartOptions = async(value,option,type) => {
      const params = {
        tag: value,
        offering: '', sub: '',
        account: '',
        subDouble: [], offeringDouble: [],
        accountDouble: [],
        cstDouble: []
      }
      let res = await $api.returnEnum(params)
      paramListObj[option] = dictionaryOpt(res.data[type])
    }
    const changeFn = {
      subPl() {
        const params = {
          tag: ['Account'],
          offering: '', sub: '',
          account: '',
          subDouble: formState.subPl, offeringDouble: [],
          accountDouble: [],
          cstDouble: []
        }
        let type = formState.subPl.length ? 'accountBySub' : 'account'
        if(!formState.subPl.length){
          getPartOptions(['SubPL'],'subPlOps','SubPL')
        }
        changeSelectOptions(params,type,'accountOps')
      },
      account() {
        const params = {
          tag: ['SubPL'],
          offering: '', sub: '',
          account: '',
          subDouble: [], offeringDouble: [],
          accountDouble: formState.account,
          cstDouble: []
        }
        let type = formState.account.length ? 'SubPLByAccount' : 'SubPL'
        // 没有的话再获取自身
        if(!formState.account.length){
          getPartOptions(['Account'],'accountOps','account')
        }
        changeSelectOptions(params,type,'subPlOps')
      },
      cst() {
        const params = {
          tag: ['Offering'],
          offering: '', sub: '',
          account: '',
          subDouble: [], offeringDouble: [],
          accountDouble: [],
          cstDouble: formState.cst
        }
        let type = formState.cst.length ? 'offeringByCstDouble' : 'offering'
        if(!formState.cst.length){
          getPartOptions(['Cst'],'cstOps','allCst')
        }
        changeSelectOptions(params,type,'offeringOps')
      },
      offering() {
        const params = {
          tag: ['Cst'],
          offering: '', sub: '',
          account: '',
          subDouble: [], offeringDouble: formState.offering,
          accountDouble: [],
          cstDouble: []
        }
        let type = formState.offering.length ? 'cst' : 'allCst'
        if(!formState.offering.length){
          getPartOptions(['Offering'],'offeringOps','offering')
        }
        changeSelectOptions(params,type,'cstOps')
      }
    }
    const orgChange = (val) => {
      changeFn[val]()
    }

    onMounted(() => {
      getSelectOptions();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    const moreSeachHanlder = () => {
      handleFormChange()
      closeMoreForm()
    }

    return {
      formState,
      show,
      paramListObj,
      biddingStatusOps,
      isFollowUpOps,
      formRef,
      handleFormChange,
      resetForm,
      handleFilter,
      updateParams,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder,
      orgChange,
      orgAllOps,
    };
  },
})
</script>

<style scoped lang="less">
.top-filter {
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 20px;
  .box{
    height: 160px;
  }
  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
  :deep(.ant-form-item-label > label > .anticon) {
    vertical-align: middle;
    margin-left: 0.2vw;
  }
}
</style>
