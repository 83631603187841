<template>
  <section class="consultant-list">
    <a-list size="small" bordered :data-source="consultantList"
      :pagination="consultantList.length > 0 ? pagination : false" id="consultant-list-content"
      class="consultant-list-content">
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item ant-list-item-no-flex">
          <a-row class="padding-container">
            <a-col :span="2">
              <div style="display: flex;flex-direction: column;align-items: center">
                <a-avatar :size="40" :src="item.photoUrl" class="avatar" @click="showEmpInfo(item.staffId, index)">
                  <template #icon>
                    <UserOutlined />
                  </template>
                </a-avatar>
                <a-button type="primary" ghost @click="showEmpInfo(item.staffId, index)" style="margin-top: 0.3vw">查看顾问信息
                </a-button>
              </div>
            </a-col>
            <a-col :span="21" class="positionLeft">
              <a-row :gutter="20" type="flex" align="middle">
                <a-col :span="9">
                  <a-row type="flex" align="middle">
                    <div class="cName">
                      <Tooltip :title=item.fullName>
                        <span @click="showEmpInfo(item.staffId, index)" class="demand-name-text">
                          {{ item.fullName }}
                        </span>
                      </Tooltip>
                      <a-tag color="blue" class="first-line consultant-name">
                        {{ item.position }}
                      </a-tag>
                      <Tooltip trigger="hover" title="匹配数量">
                        <div class="circle" v-if="item.acount !== null">{{ item.acount }}</div>
                      </Tooltip>
                    </div>
                  </a-row>
                  <a-row :gutter="20" class="secondRow" type="flex" align="middle">
                    <a-col :span="24">
                      <div class="iconContainer">
                        <div class="iconItem">
                          <Tooltip title="职级">
                            <BarChartOutlined class="icon" />
                            <!-- <img src="@/assets/images/levelBlue.png" class="icon img" /> -->
                          </Tooltip>
                          {{ item.psGroup }} |
                        </div>
                        <div class="iconItem">
                          <Tooltip title="Office">
                            <EnvironmentOutlined class="icon" />
                          </Tooltip>
                          {{ item.workLocation }} |
                        </div>
                        <div class="iconItem">
                          <Tooltip title="Status">
                            <TagOutlined class="icon" />
                          </Tooltip>
                          {{ item.status ? item.status : "-" }}
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="15">
                  <a-row>
                    <a-col :span="24">
                      <div class="projectContainer">
                        <div class="projectLeft">
                          <div class="projectTop">
                            <span class="fontBolder fontLarge" v-if="item.account != null">Account: </span>
                            <span class="fontBolder fontLarge" v-if="item.account != null">{{ item.account }}</span>
                          </div>
                          <div class="projectMiddle">
                            <span class="fontBolder" v-if="item.engagementDescription != null">Project Description: </span>
                            <span class="fontBlue" v-if="item.engagementDescription != null">{{ item.engagementDescription }}</span>
                          </div>
                          <div class="projectBottom" v-if="item.lastActualReleaseDate != null && item.lastActualReleaseDate != null && item.estimateReleaseDate != null">
                            <span class="fontBolder">Assignment Term: </span>
                            <span class="fontBlue">{{ item.onboardDate }} - {{
                                (item.lastActualReleaseDate != null && item.lastActualReleaseDate != "-") ?  item.lastActualReleaseDate : item.estimateReleaseDate
                            }}</span>
                          </div>
                        </div>
                        <div class="projectRight">
                          <div class="projectTop">
                            <span class="fontBolder" v-if="item.hoursPerDay != null">Hours Per Day: </span>
                            <span class="fontBlue" v-if="item.hoursPerDay != null">{{ item.hoursPerDay }}</span>
                          </div>
                          <!-- <div class="projectMiddle">
                            <span class="fontBolder">Avaliable Date: </span>
                            <span class="fontBlue">{{ item.availabilitystartDate }}</span>
                          </div> -->
                        </div>
                      </div>
                    </a-col>

                  </a-row>
                </a-col>
              </a-row>
              <a-row :gutter="20" class="skill-row" type="flex" align="middle">
                <a-col :span="1">
                  <Tooltip title="技能">
                    <TrophyOutlined class="icon" />
                  </Tooltip>
                </a-col>
                <a-col :span="23" class="positionRight" :class="[
                  'skill-col',
                  item.showMore ? '' : 'skill-hidden',
                ]">
                  <div class="more" v-show="skillRef[index] &&
                    skillRef[index].scrollHeight - 30 > 0
                    " @click="moreClick(item, index)">
                    {{ item.showMore ? "收起" : "更多" }}
                    <CaretDownOutlined :rotate="item.showMore ? 180 : 0" />
                  </div>
                  <div :ref="(el) => (skillRef[index] = el)" class="skillContainer">
                    <a-tag v-for="(skill, skillIndex) in generateSkills(
                      item.skills
                    )" :key="skillIndex" class="skilltag">
                      {{ skill }}
                    </a-tag>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="1" class="positionLeft">
              <div :class="disableButton == false ? 'add-btn' : 'disable-add'" @click="add(item)"
                v-show="!matchListId.includes(item.staffId)">
                <SwapOutlined />
              </div>

              <div :class="disableButton == false ? 'added-btn' : 'disable-added'" @click="removeAdded(item.staffId)"
                v-show="matchListId.includes(item.staffId)">
                <CheckOutlined style="color: white; font-size: 14px;margin-top: 0.2vw" />
              </div>
            </a-col>
          </a-row>
        </a-list-item>
      </template>
    </a-list>
    <ModalBox :class="iconRed ? 'icon-red' : ''" v-model="modelValue" :ok="handleOk" :content="content"
      :showIconCom="showIconCom" :footer="footer" />
  </section>
  <emp-info v-if="isShowingEmpInfo" :is-have-add="true" :isShowingEmpInfo="isShowingEmpInfo" :userDataList="userDataList"
    :getId="getId" :getIndex="getIndex" @add-emp-list="addEmp" @close-emp-info="closeEmpInfo" @add-user="addUser"
    cancelText="取消" sumbitText="添加至匹配器" />
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  onBeforeUpdate,
  onActivated,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import moment from "moment";
import {
  EnvironmentOutlined,
  TagOutlined,
  SwapOutlined,
  UserOutlined,
  TrophyOutlined,
  CheckOutlined, CaretDownOutlined,
  BarChartOutlined
} from "@ant-design/icons-vue";
import EmpInfo from "@/components/EmpInfo.vue";
import ModalBox from '@/components/ModalBox'
import Tooltip from "@/components/Tooltip.vue";
// 默认筛选 入职 / 即将入职 && withOut JTP
let queryCache = {
  jobStatus: ["1"],
  withJTP: '0',
  skillName: []
};
export default defineComponent({
  name: "ConsultantList",
  components: {
    CaretDownOutlined,
    Tooltip,
    EnvironmentOutlined,
    BarChartOutlined,
    TagOutlined,
    SwapOutlined,
    UserOutlined,
    TrophyOutlined,
    CheckOutlined,
    EmpInfo,
    ModalBox,
  },
  props: {
    contentStyleObj: Object
  },
  setup() {
    const iconRed = ref(false)
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const consultantInfoRef = ref();
    const consultantList = ref([]);
    const matcherConsultantList = ref([]);
    const matchListId = ref([])
    const disableButton = ref(false);
    const isShowingEmpInfo = ref(false);
    const userDataList = reactive({});
    const userStarList = ref([]);
    const getId = ref();
    const getIndex = ref();
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        getConsultantListData(queryCache);
        // 回到页面顶端
        document.getElementById("consultant-list-content").parentElement.scrollTop = 0
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        getConsultantListData(queryCache);
      },
    });
    onActivated(() => {
      getConsultantListData(queryCache);
    });

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.value.current = 1;
      }
    };
    // 获取列表数据
    const getConsultantListData = (queryData) => {
      queryCache = queryData ? queryData : {};
      const data = Object.assign(
        { ...queryData },
        {
          pageSize: pagination.value.pageSize,
          pageIndex: pagination.value.current,
        }
      );
      $api.queryChangeCounsultCondition(data).then((res) => {
        consultantList.value = res.data.resource;
        pagination.value.total = res.data.total;
      });
    };

    const generateSkills = (returnSkills) => {
      if (returnSkills && returnSkills.length) {
        return returnSkills.split(' ;')
      } else {
        return [];
      }
    };
    const addEmp = (data) => {
      matcherConsultantList.value.push(data);
    };
    // 监听项目补充说明点击
    let activedItemIndex = ref(-1);
    const handleMoreInfoClick = (index) => {
      if (activedItemIndex.value === index) {
        activedItemIndex.value = -1;
      } else {
        activedItemIndex.value = index;
      }
    };

    const showEmpInfo = (id, index) => {
      getId.value = id;
      getIndex.value = index;
      $api.getOneUser(id).then((res) => {
        if (res.code == "200") {
          isShowingEmpInfo.value = true;
          Object.assign(userDataList, res.data);
          if (res.data.onboardDate != null) {
            userDataList.onboardDate = moment(res.data.onboardDate).format(
              "YYYY-MM-DD"
            );
          }
        }
      });
    };

    // reset项目补充说明
    const resetActiveItem = () => {
      activedItemIndex.value = -1;
    };
    // 查看项目详情
    const checkConsultantDetails = (details) => {
      consultantInfoRef.value.showModal(details);
    };
    onMounted(() => {
      document.addEventListener("click", resetActiveItem);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", resetActiveItem);
    });

    //将用户数据传输到匹配器
    const addFunc = (data) => {
      if (!matchListId.value.includes(data.staffId)) {
        matcherConsultantList.value.push(data);
        matchListId.value.push(data.staffId);
      }
      $EventBus.emit("matchConsultant", matcherConsultantList);
    };

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOk = () => {
      modalData.value ? addFunc(modalData.value) : '';
    }
    const add = async (data) => {
      // 校验用户是否存在匹配情况
      let res = await $api.verifyConsultisInDemand({ userId: data.staffId })
      if (res.data === true) {
        modalData.value = data
        content.value = '该人员被匹配到其他项目，是否继续？'
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = false
        modelValue.value = true
      } else {
        addFunc(data);
      }
    };

    //将用户数据从匹配器中移除
    const removeAdded = (data) => {
      matchListId.value.splice(matchListId.value.findIndex((item) => item === data),1)
      matcherConsultantList.value.splice(matcherConsultantList.value.findIndex((item) => item.staffId === data),1)
    };

    $EventBus.on("disableButton", (data) => {
      disableButton.value = data;
    });

    $EventBus.on("deleteConsult", (data) => {
      matchListId.value = data.map((item)=>{return item.staffId})
      matcherConsultantList.value = data
    });

    const closeEmpInfo = () => {
      isShowingEmpInfo.value = false;
    };

    const addUser = (index) => {
      add(consultantList.value[index]);
    };

    let skillRef = ref([]);
    onBeforeUpdate(() => {
      skillRef.value = [];
    });
    const moreClick = (item) => {
      item.showMore = !item.showMore;
    };

    const resetMatch = () => {
      matcherConsultantList.value = [];
      pagination.value.current = 1;
    };

    return {
      matcherConsultantList,
      consultantList,
      pagination,
      activedItemIndex,
      consultantInfoRef,
      disableButton,
      resetCurrent,
      getConsultantListData,
      handleMoreInfoClick,
      checkConsultantDetails,
      add,
      removeAdded,
      generateSkills,
      showEmpInfo,
      closeEmpInfo,
      isShowingEmpInfo,
      userDataList,
      addEmp,
      userStarList,
      getId,
      getIndex,
      addUser,
      moreClick,
      skillRef,
      resetMatch,
      iconRed,
      modelValue,
      content,
      showIconCom,
      footer,
      handleOk,
      matchListId
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式y
::v-deep .ant-popover {
  padding-right: 10px;
}

::v-deep .ant-popover-arrow {
  display: none;
}

::v-deep .ant-list-items {
  //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-list-empty-text) {
  //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  width: 100%;
  display: table;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.consultant-list {
  //overflow: scroll;

  .consultant-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 17.3vw);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
    margin-top: 6px;
  }

  .img {
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
    margin-top: 0px;
  }

  .icon-eye {
    font-family: bold;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    padding: 24px 20px 20px;

    .positionLeft {
      position: relative;
      left: 15px;

      .positionRight {
        position: relative;
        right: 15px;
      }
    }

    .avatar {
      //margin-left: 15px;

      & :hover {
        cursor: pointer;
      }
    }

    .cName {
      font-size: 0.93vw;
      display: flex;

      .circle {
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        background-color: rgb(233, 246, 254);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 0.5rem;
      }

      .demand-name-text {
        cursor: pointer;
        display: inline-block;
        margin-right: 0.41667vw;
        //overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //line-height: 1.30208vw;
        //font-size: .9375vw;
        color: #4d4d4d;
        font-weight: 600;
        border-bottom: 1px solid #4d4d4d;
        cursor: pointer;
      }
    }
  }

  .secondRow {
    margin: 10px 0;

    .col-eye:hover {
      cursor: pointer;
    }

    .icon {
      color: #3182CE;
    }
  }

  .first-line {
    font-size: 18px;
    color: #3182CE;
    margin-bottom: 12px;
    border: 0;
    background: #E9F6FE;
  }

  .consultant-name {
    cursor: pointer;
  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .more-info {
    padding: 0 32px 24px;
    display: flex;
    align-items: center;
    width: 200px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .skillContainer {
    width: 97%;
    .skilltag {
      color: #999999;
      background: #FAFAFA;
    }
  }

  .suplement {
    line-height: 32px;
    background: #d0e8ff;
    padding: 0 32px;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: all 2s linear;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 1px;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }
    }
  }

  .added-btn {
    background-color: #1890ff;
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }

  .disable-add {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
  }

  .disable-added {
    background-color: #1890ff;
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
  }
}

.iconContainer {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}

.projectContainer {
  width: 100%;
  height: 85px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  .fontLarge {
    font-size: 0.93vw;
  }

  .fontBlue {
    color: #1890ff;
  }

  .fontBolder {
    font-weight: 600;
  }

  .fontSmall {
    font-size: 0.9rem;
  }

  .projectTop {
    height: 23px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
  }

  .projectMiddle {
    height: 30px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
  }

  .projectBottom {
    height: 23px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
  }

  .projectLeft {
    flex: 8;
  }

  .projectRight {
    flex: 3;
  }

}

// 更多
.skill-row {
  margin-top: -5px;
  flex-wrap: nowrap;

  .icon {
    color: #3182CE;
  }
}

.skill-col {
  position: relative;
  overflow: hidden;
  padding-right: 40px;
  white-space: normal;

  .ant-tag {
    margin-top: 5px;
  }

  .more {
    position: absolute;
    right: 0px;
    top: 5px;
    line-height: 22px;
    color: #1890ff;
    cursor: pointer;
  }
}

.skill-hidden {
  height: 30px;
}

.threeInfos {
  width: 1000px;
}

:deep(.ant-list-bordered) {
  border: 0px;
}

.icon-red {
  :deep(.ant-modal-body .anticon-exclamation-circle) {
    color: #DC5E4F;
  }
}

:deep(.ant-pagination) {
  justify-content: flex-end;
}

:deep(.ant-spin-nested-loading) {
  //height: calc(100vh - 17.3vw);
  //overflow-y: auto;
}</style>
