<template>
  <div class="customize-create">
    <a-form
        :model="formState"
        :rules="formRules"
        layout="vertical"
        ref="formRef"
        class="customize-form"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="需求名称" name="demandName" class="customize-formItem">
            <a-input
                class="form-item-inline"
                v-model:value="formState.demandName"
                placeholder="请输入"
                allowClear
                autocomplete="off"
                :maxLength="50"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户服务经理" name="csm" class="customize-formItem">
            <a-select
                v-model:value="formState.csm"
                placeholder="Name/GGID"
                show-search
                allowClear
                :filter-option="filterOption"
                :options="csmOps"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户名称" name="customerName" class="customize-formItem">
            <a-select
                v-model:value="formState.customerName"
                placeholder="请选择"
                show-search
                allowClear
                @change="customerNameChange"
            >
              <a-select-option
                  v-for="item in customerNameOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="行业线" name="industryLine" class="customize-formItem">
            <a-input
                class="form-item-inline"
                v-model:value="formState.industryLine"
                :disabled="true"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="详细客户名称" name="customerNameDetail" class="customize-formItem">
            <a-input
                class="form-item-inline"
                v-model:value="formState.customerNameDetail"
                placeholder="请输入"
                allowClear
                autocomplete="off"
                :maxLength="50"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="预计用人时长（月）" name="useStaffLength" class="customize-formItem">
            <a-input-number
                v-if="typeValue != 'look'"
                v-model:value="formState.useStaffLength"
                allowClear
                :min="1"
                :max="12"
                placeholder="请输入"
                style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="末级需求部门" name="demandDeptName" class="customize-formItem">
            <a-select
                v-model:value="formState.demandDeptName"
                placeholder="请选择"
                show-search
                allowClear
                @change="demandDeptChange"
            >
              <a-select-option
                  v-for="item in demandDeptNameOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="部门层级" name="deptHierarchy" class="customize-formItem">
            <a-input
                class="form-item-inline"
                v-model:value="formState.deptHierarchy"
                :disabled="true"
            />
          </a-form-item>
        </a-col>
        <a-divider />
        <a-col :span="12">
          <a-form-item label="岗位名称" name="demandPosition" class="customize-formItem">
            <a-select
                v-model:value="formState.demandPosition"
                placeholder="请选择"
                show-search
                allowClear
            >
              <a-select-option
                  v-for="item in demandPositionOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="人员级别" name="staffGrade" class="customize-formItem">
            <a-select
                v-model:value="formState.staffGrade"
                placeholder="请选择"
                show-search
                allowClear
            >
              <a-select-option
                  v-for="item in staffGradeOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="工作地点" name="workLocation" class="customize-formItem">
            <a-select
                v-model:value="formState.workLocation"
                placeholder="请选择"
                show-search
                allowClear
            >
              <a-select-option
                  v-for="item in workLocationOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="项目确定性" name="projectCertainty" class="customize-formItem">
            <a-select
                v-model:value="formState.projectCertainty"
                placeholder="请选择"
                show-search
                allowClear
            >
              <a-select-option
                  v-for="item in projectCertaintyOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="期望到岗时间" name="expectArrivalTime" class="customize-formItem">
            <a-date-picker
                placeholder="请选择"
                v-model:value="formState.expectArrivalTime"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="需求性质" name="demandNature" class="customize-formItem">
            <a-select
                v-model:value="formState.demandNature"
                placeholder="请选择"
                show-search
                allowClear
                style="width: 100%"
            >
              <a-select-option
                  v-for="item in demandNatureOps"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="footer">
      <a-divider />
      <div style="float: right;">
        <a-button @click="closeModal" class="mr12">取消</a-button>
        <a-button @click="saveData" type="primary">添加至匹配器</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {useGlobalPropertyHook} from "@/hooks/common";
import {onMounted, reactive, ref, watch} from "vue";
import {dictionaryOpt, dictionaryOptions, dictionaryOptionsCode} from "@/utils/common";
import {message} from "ant-design-vue";

export default {
  name: "CustomizeCreate",
  components: {},
  emits: ["handle-close", "handle-save"],
  props: {
    customizeFormData: {
      type: Object,
      default: () => ({}),
    },
    customizeOps: {
      type: Object,
      default: () => ({
        requirementNameOps: [],
        demandDeptNameOps: [],
        demandPositionOps: [],
        staffGradeOps: [],
        workLocationOps: [],
      }),
    },
    isCustomizeCreate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const formRef = ref()
    const formState = reactive({
      demandName: "",
      csm: undefined,
      customerName: undefined,
      industryLine: "",
      customerNameDetail: "",
      demandDeptName: undefined,
      deptHierarchy: "",
      demandPosition: undefined,
      staffGrade: undefined,
      workLocation: undefined,
      projectCertainty: undefined,
      expectArrivalTime: "",
      demandNature: undefined,
    })
    const csmOps = ref([])
    const customerNameOps = ref([])
    const demandDeptNameOps = ref([])
    const demandPositionOps = ref([])
    const staffGradeOps = ref([])
    const workLocationOps = ref([])
    const projectCertaintyOps = dictionaryOpt(['Firm','Proposal'])
    const demandNatureOps = dictionaryOpt(['新增','顶替'])

    const formRules = {
      demandName: [
        {
          required: true,
          message: "请输入需求名称",
          trigger: "change",
        },
      ],
      csm: [
        {
          required: true,
          message: "请选择客户服务经理",
          trigger: "change",
        },
      ],
      customerName: [
        {
          required: true,
          message: "请选择客户名称",
          trigger: "change",
        },
      ],
      industryLine: [
        {
          required: true,
          message: "请输入行业线",
          trigger: "change",
        },
      ],
      customerNameDetail: [
        {
          required: true,
          message: "请输入详细客户名称",
          trigger: "change",
        },
      ],
      demandDeptName: [
        {
          required: true,
          message: "请选择末级需求部门",
          trigger: "change",
        },
      ],
      deptHierarchy: [
        {
          required: true,
          message: "请输入部门层级",
          trigger: "change",
        },
      ],
      demandPosition: [
        {
          required: true,
          message: "请选择岗位名称",
          trigger: "change",
        },
      ],
      staffGrade: [
        {
          required: true,
          message: "请选择人员级别",
          trigger: "change",
        },
      ],
      workLocation: [
        {
          required: true,
          message: "请选择工作地点",
          trigger: "change",
        },
      ],
      projectCertainty: [
        {
          required: true,
          message: "请选择项目确定性",
          trigger: "change",
        },
      ],
      demandNature: [
        {
          required: true,
          message: "请选择需求性质",
          trigger: "change",
        },
      ],
      expectArrivalTime: [
        {
          required: true,
          message: "请选择期望到岗时间",
          trigger: "change",
        },
      ],
      useStaffLength: [
        {
          required: true,
          pattern: /^(1[0-2]|[1-9])$/,
          message: "请填写1~12之间的整数",
          trigger: "change",
        },
      ],
    };

    const filterOption = (input, option) => {
      if (!input) return false;
      let reg = /[\s|/,|/，]*/g
      return option.value.replace(reg,'').toUpperCase().indexOf(input.replace(reg,'').toUpperCase()) >= 0||option.ggid.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    const customerNameChange = (value) => {
      if(value){
        $api.getAccountPl(value).then((res) => {
          formState.industryLine = res.data.accountPl[0]
        });
      } else {
        formState.industryLine = ''
      }
    }

    const demandDeptChange = (value) => {
      if(value){
        $api.getThreeTwoList(value).then((res) => {
          formState.deptHierarchy = res.data.orgThreeTwoList[0]
        });
      } else {
        formState.deptHierarchy = ''
      }
    }

    const closeModal = () => {
      formRef.value.resetFields();
      $EventBus.emit("matchCustomize", "");
      emit("handle-close")
    }

    const saveData = () => {
      formRef.value.validate().then(() => {
        message.success("更新成功")
        emit("handle-save",formState)
        $EventBus.emit("matchCustomize",JSON.parse(JSON.stringify(formState)));
      });
    }
    const maConsultantList = reactive({
      demandPosition: '',
      staffGrade: '',
      workLocation: '',
    })

    $EventBus.on("matchConsultant", (matcherConsultantList) => {
      formState.demandPosition = matcherConsultantList?.value[0].position
      formState.staffGrade = matcherConsultantList?.value[0].psGroup
      formState.workLocation = matcherConsultantList?.value[0].workLocation
      maConsultantList.demandPosition = matcherConsultantList?.value[0].position
      maConsultantList.staffGrade = matcherConsultantList?.value[0].psGroup
      maConsultantList.workLocation = matcherConsultantList?.value[0].workLocation
    });

    watch(
        () => props.isCustomizeCreate,
        (val) => {
          if(val) {
            //下拉数据
            customerNameOps.value = dictionaryOpt(props.customizeOps.requirementNameOps)
            demandDeptNameOps.value = dictionaryOpt(props.customizeOps.demandDeptNameOps)
            demandPositionOps.value = dictionaryOptions(props.customizeOps.demandPositionOps)
            staffGradeOps.value = dictionaryOptionsCode(props.customizeOps.staffGradeOps)
            workLocationOps.value = dictionaryOptionsCode(props.customizeOps.workLocationOps)
            csmOps.value = props.customizeOps.csmOps
            //表单数据
            if(props.customizeFormData.type === 'add') {
              Object.keys(formState).forEach(key=>{formState[key]=undefined})
              Object.assign(formState,maConsultantList)
            } else {
              Object.assign(formState,props.customizeFormData)
              formRef.value.validate()
            }
          }
        },
    )
    onMounted(() => {

    })
    return {
      formState,
      csmOps,
      customerNameOps,
      demandDeptNameOps,
      demandPositionOps,
      staffGradeOps,
      workLocationOps,
      projectCertaintyOps,
      demandNatureOps,
      closeModal,
      saveData,
      formRules,
      formRef,
      customerNameChange,
      demandDeptChange,
      filterOption,
      maConsultantList,
    };
  },
}
</script>

<style scoped lang="less">
.customize-create {
  overflow-y: auto;
  height: 100% !important;

  .customize-form {
    max-height: 29vw;
    overflow-y: auto;
    margin: 20px 20px 0 40px;
    .customize-formItem {
      width: 60%;
    }
  }
  .footer {
    margin: 20px 20px 0 40px;
  }
}
</style>
