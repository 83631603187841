<template>
    <div>
        <section class="demand-list">
            <!-- 直接copy过来的DemandList和customzeList的对应片段和css样式 -->
            <template v-if="demandType === 1">
                <a-list size="small" bordered :data-source="demandObj" :pagination="false" id="demand-list-content"
                    class="demand-list-content">
                    <template #renderItem="{ item, index }">
                        <a-list-item class="list-item ant-list-item-no-flex">
                            <a-row class="padding-container">
                                <a-col :span="24">
                                    <a-row>
                                        <a-col :span="10" style="padding-right: 3vw">
                                            <p class="first-line demand-name">
                                                <span class="num" @click="checkDemandDetails(item)">{{ item.demandNumber
                                                }}</span>
                                                <Tooltip :title=item.demandName>
                                                    <span class="demand-name-text" @click="checkDemandDetails(item)">{{
                                                        item.demandName }}</span>
                                                </Tooltip>
                                                <a-tag color="blue" v-show="item.completeChannel" class="completetag">
                                                    {{ item.completeChannel }}
                                                </a-tag>
                                            </p>
                                            <ul class="key-msg-list sec-line">
                                                <li class="key-msg-item">
                                                    <Tooltip title="人员级别">
                                                        <img src="@/assets/iconfont/level.png" class="icon img" />
                                                    </Tooltip>
                                                    {{ item.staffGrade }}
                                                </li>
                                                <li class="key-msg-item">
                                                    <Tooltip title="预计用人时长">
                                                        <ClockCircleOutlined class="icon" />
                                                    </Tooltip>
                                                    {{ item.useStaffLength }}
                                                </li>
                                                <li class="key-msg-item">
                                                    <Tooltip title="到岗时间">
                                                        <img src="@/assets/iconfont/ScheduleOndutytime.png"
                                                            class="icon img" />
                                                    </Tooltip>
                                                    {{ item.expectArrivalTime }}
                                                </li>
                                                <li class="key-msg-item">
                                                    <Tooltip title="工作地点">
                                                        <EnvironmentOutlined class="icon" />
                                                    </Tooltip>
                                                    {{ item.workLocation }}
                                                </li>
                                            </ul>
                                        </a-col>
                                        <a-col :span="6">
                                            <p class="first-line">
                                                <Tooltip :title=item.projectName>
                                                    项目名称：{{ item.projectName }}
                                                </Tooltip>
                                            </p>
                                            <ul class="key-msg-list sec-line" style="color: #3182CE;margin-left: 0.2vw">
                                                <li class="key-msg-item">
                                                    <Tooltip title="优先级">
                                                        <OrderedListOutlined class="icon" />
                                                    </Tooltip>
                                                    <i class="highest">{{ item.priority }}</i>
                                                </li>
                                            </ul>
                                        </a-col>
                                        <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                                            <p class="first-line">
                                                <Tooltip :title=item.customerNameDetail>
                                                    详细客户名称：{{ item.customerNameDetail }}
                                                </Tooltip>
                                            </p>
                                            <p class="sec-line" style="color: #3182CE">
                                                <Tooltip title="客户服务经理(CSM)">
                                                    <UserOutlined class="icon" />
                                                </Tooltip>
                                                {{ item.csm }}
                                            </p>
                                        </a-col>
                                    </a-row>
                                </a-col>

                            </a-row>
                            <div class="Project-Supplementary-Description">
                                <div class="more-info" @click.stop="handleMoreInfoClick(index)">
                                    <DownCircleFilled class="more-info-icon"
                                        :rotate="index == activedItemIndex ? 180 : 0" />
                                    项目补充说明
                                </div>
                                <p :class="[
                                    'suplement',
                                    index == activedItemIndex ? 'active' : 'un-active',
                                ]">
                                    <span>
                                        {{ item.projectSupplement ? item.projectSupplement : "无" }}
                                    </span>
                                </p>
                            </div>
                        </a-list-item>
                    </template>
                </a-list>
            </template>
            <template v-if="demandType === 2">
                <section class="pipeline-list">
                    <a-list size="small" bordered :data-source="demandObj" :pagination="false"
                        class="pipeline-list-content">
                        <template #renderItem="{ item, index }">
                            <a-list-item class="list-item ant-list-item-no-flex">
                                <a-row class="padding-container">
                                    <a-col :span="22">
                                        <a-row>
                                            <a-col :span="10" style="padding-right: 3vw">
                                                <p class="first-line pipeline-name">
                                                    <span class="num" @click="checkPipelineDetails(item)">{{ item.pipelineId
                                                    }}</span>
                                                    <Tooltip :title=item.opportunityName>
                                                        <span class="pipeline-name-text"
                                                            @click="checkPipelineDetails(item)">{{ item.opportunityName
                                                            }}</span>
                                                    </Tooltip>
                                                    <a-tag color="green" v-show="item.type" class="completetag">
                                                        {{ item.type === '2' ? 'PPL' : '' }}
                                                    </a-tag>
                                                    <Tooltip trigger="hover" :title=item.mateNumberTitle>
                                                        <a-tag class="numbertag">
                                                            {{ item.currentValidMatchingNum }}
                                                        </a-tag>
                                                    </Tooltip>
                                                    <Tooltip trigger="hover" :title=item.transferOrderNumberTitle>
                                                        <a-tag class="numbertag">
                                                            {{ item.currentValidAssignmentNum }}
                                                        </a-tag>
                                                    </Tooltip>
                                                </p>
                                                <ul class="key-msg-list sec-line">
                                                    <li class="key-msg-item" style="max-width: 33%">
                                                        <Tooltip title="CST">
                                                            <img src="@/assets/images/customize-ind.png" class="icon img" />
                                                        </Tooltip>
                                                        <Tooltip :title=item.cst>
                                                            <span class="key-msg-span">{{ item.cst }}</span>
                                                        </Tooltip>
                                                    </li>
                                                    <li class="key-msg-item" style="max-width: 33%">
                                                        <Tooltip title="Offering">
                                                            <img src="@/assets/images/pip-offering.png" class="icon img" />
                                                        </Tooltip>
                                                        <Tooltip :title=item.offering>
                                                            <span class="key-msg-span">{{ item.offering }}</span>
                                                        </Tooltip>
                                                    </li>
                                                    <li class="key-msg-item" style="max-width: 33%">
                                                        <Tooltip title="SPOC">
                                                            <img src="@/assets/images/customize-csm.png" class="icon img" />
                                                        </Tooltip>
                                                        <Tooltip :title=item.spoc>
                                                            <span class="key-msg-span">{{ item.spoc }}</span>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </a-col>
                                            <a-col :span="6">
                                                <p class="first-line">
                                                    <Tooltip :title=item.account>
                                                        <span style="color: #4d4d4d;font-weight: 600;">Account：{{
                                                            item.account }}</span>
                                                    </Tooltip>
                                                </p>
                                                <ul class="key-msg-list sec-line" style="color: #3182CE">
                                                    <li class="key-msg-item">
                                                        <Tooltip title="Start Date">
                                                            <ScheduleOutlined class="icon" />
                                                        </Tooltip>
                                                        <i class="highest">Start Date {{ item.startDate }}</i>
                                                    </li>
                                                </ul>
                                            </a-col>
                                            <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                                                <a-row>
                                                    <a-col span="15">
                                                        <div class="key-msg-list sec-line-icon"
                                                            style="margin-bottom: 12px;">
                                                            <Tooltip title="Probability %">
                                                                <img src="@/assets/images/Probability.png"
                                                                    class="icon img" />Probability %:
                                                            </Tooltip>
                                                            {{ item.probability }}
                                                        </div>
                                                    </a-col>
                                                    <a-col span="9">
                                                        <div class="key-msg-list sec-line-icon"
                                                            style="margin-bottom: 12px;">
                                                            <Tooltip title="DRB Stage">
                                                                <MenuOutlined class="icon" />DRB Stage:
                                                            </Tooltip>
                                                            {{ item.drbStage }}
                                                        </div>
                                                    </a-col>
                                                    <a-col span="15">
                                                        <div class="key-msg-list sec-line-icon"
                                                            style="margin-bottom: 12px;">
                                                            <Tooltip title="Bidding Status">
                                                                <img src="@/assets/images/Bidding.png" class="icon img" />BD
                                                                Status:
                                                            </Tooltip>
                                                            {{ item.biddingStatus }}
                                                        </div>
                                                    </a-col>
                                                    <a-col span="9">
                                                        <div class="key-msg-list sec-line-icon"
                                                            style="margin-bottom: 12px;">
                                                            <Tooltip title="CSM">
                                                                <UserOutlined class="icon" />CSM:
                                                            </Tooltip>
                                                            {{ item.csm }}
                                                        </div>
                                                    </a-col>
                                                </a-row>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                    <a-col :span="2">

                                    </a-col>
                                </a-row>
                                <div class="Project-Supplementary-Description">
                                    <div class="more-info" @click.stop="handleMoreInfoClick(index)">
                                        <DownCircleFilled class="more-info-icon"
                                            :rotate="index == activedItemIndex ? 180 : 0" />
                                        项目进展情况
                                    </div>
                                    <p :class="[
                                        'suplement',
                                        index == activedItemIndex ? 'active' : 'un-active',
                                    ]">
                                        <span>
                                            {{ item.projectStatus ? item.projectStatus : "无" }}
                                        </span>
                                    </p>
                                </div>
                            </a-list-item>
                        </template>
                    </a-list>
                </section>
            </template>
            <template v-if="demandType === 3">
                <section class="customize-list">
                    <a-list size="small" bordered :data-source="demandObj" class="customize-list-content">
                        <template #renderItem="{ item }">
                            <a-list-item class="list-item ant-list-item-no-flex">
                                <a-row class="padding-container">
                                    <a-col :span="22">
                                        <a-row>
                                            <a-col :span="10" style="padding-right: 3vw">
                                                <p class="first-line customize-name">
                                                    <span class="num" @click="checkCustomizeDetails(item)">{{
                                                        item.demandNumber }}</span>
                                                    <Tooltip :title=item.demandName>
                                                        <span class="customize-name-text"
                                                            @click="checkCustomizeDetails(item)">{{ item.demandName
                                                            }}</span>
                                                    </Tooltip>
                                                    <a-tag color="yellow" v-show="item.type" class="completetag">
                                                        {{ item.type === '1' ? '自定义' : '' }}
                                                    </a-tag>
                                                    <Tooltip trigger="hover" :title=item.mateNumberTitle>
                                                        <div class="circle" style="margin-right: 0.3vw">{{ item.mateNumber
                                                            == undefined ? 0 : item.mateNumber }}</div>
                                                    </Tooltip>
                                                    <Tooltip trigger="hover" :title=item.transferOrderNumberTitle>
                                                        <div class="circle">{{ item.transferOrderNumber == undefined ? 0 :
                                                            item.transferOrderNumber }}</div>
                                                    </Tooltip>
                                                </p>
                                                <ul class="key-msg-list sec-line">
                                                    <li class="key-msg-item">
                                                        <Tooltip title="人员级别">
                                                            <img src="@/assets/iconfont/level.png" class="icon img" />
                                                        </Tooltip>
                                                        {{ item.staffGrade }}
                                                    </li>
                                                    <li class="key-msg-item">
                                                        <Tooltip title="期望到岗时间">
                                                            <img src="@/assets/iconfont/ScheduleOndutytime.png"
                                                                class="icon img" />
                                                        </Tooltip>
                                                        {{ item.expectArrivalTime }}
                                                    </li>
                                                    <li class="key-msg-item">
                                                        <Tooltip title="工作地点">
                                                            <EnvironmentOutlined class="icon" />
                                                        </Tooltip>
                                                        {{ item.workLocation }}
                                                    </li>
                                                    <li class="key-msg-item">
                                                        <Tooltip title="项目确定性">
                                                            <img src="@/assets/images/customize-Certainty.png"
                                                                class="icon img" />
                                                        </Tooltip>
                                                        {{ item.projectCertainty }}
                                                    </li>
                                                </ul>
                                            </a-col>
                                            <a-col :span="6">
                                                <p class="first-line">
                                                    <Tooltip :title=item.customerName>
                                                        客户名称：{{ item.customerName }}
                                                    </Tooltip>
                                                </p>
                                                <ul class="key-msg-list sec-line" style="color: #3182CE;margin-left: 0.2vw">
                                                    <li class="key-msg-item">
                                                        <Tooltip title="末级需求部门">
                                                            <img src="@/assets/images/demandDeptName.png"
                                                                class="icon img" />
                                                        </Tooltip>
                                                        <i class="highest" style="margin-top: 0.1vw">{{ item.demandDeptName
                                                        }}</i>
                                                    </li>
                                                </ul>
                                            </a-col>
                                            <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                                                <p class="first-line">
                                                    <Tooltip :title=item.customerNameDetail>
                                                        详细客户名称：{{ item.customerNameDetail }}
                                                    </Tooltip>
                                                </p>
                                                <p class="sec-line" style="color: #3182CE">
                                                    <Tooltip title="客户服务经理(CSM)">
                                                        <UserOutlined class="icon" />
                                                    </Tooltip>
                                                    {{ item.csm }}
                                                </p>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>
                            </a-list-item>
                        </template>
                    </a-list>
                </section>
            </template>

        </section>
        <div class="arveTable">
            <a-form ref="formRef" :model="tableData">
                <a-table ref="tableRef" :columns="columns" :data-source="tableData" :pagination="false" row-key="id"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <template #name="{ record }">
                        <div style="cursor: pointer;text-decoration:underline" @click="clickName(record)">{{ record.fullName
                        }}</div>
                    </template>
                    <template #arveAssumption="{ record }">
                        <a-form-item style="position:relative;top:10px" name="arveAssumption" :rules="[
                            {
                                validator: (info) => validateArve(info, record, 'arveAssumption'),
                                trigger: ['change', 'blur'],
                            }]">
                            <a-input style="width:150px" v-model:value="record.arveAssumption" placeholder="请输入" />
                        </a-form-item>
                    </template>
                    <template #arveAssumptionStartDate="{ record }">
                        <a-form-item style="position:relative;top:8px" name="arveAssumptionStartDate" :rules="[
                            {
                                validator: (info) => validateArve(info, record, 'arveAssumptionStartDate'),
                                trigger: ['change', 'blur'],
                            }]">
                            <a-date-picker valueFormat="YYYY-MM-DD" style="width:150px"
                                v-model:value="record.arveAssumptionStartDate" placeholder="请输入" />
                        </a-form-item>
                    </template>
                </a-table>
            </a-form>
        </div>
        <div class="selectPm">
            <div class="top">
                <template v-if="demandType != 2">
                    <a-form ref="pmForm" :model="pmData" :rules="rules">
                        <a-form-item name="selectPM" label="选择PM">
                            <a-select :dropdownAlign="{ overflow: { adjustY: false } }" :dropdownStyle="{ maxHeight: '60px' }"
                                style="width:200px" v-model:value="pmData.selectPM" show-search placeholder="选择PM"
                                :show-arrow="false" :filter-option="filterOption" :options="pmOption">
                            </a-select>
                        </a-form-item>
                    </a-form>
                </template>
                <template v-if="demandType == 2">
                    <span class="text">是否将预匹配发送至PM?</span><span class="text redTop">*</span>
                    <a-radio-group @change="changeRadio" v-model:value="canSelectPM" :options="selectPMOption" />
                    <template v-if="canSelectPM == '是'">
                        <a-form ref="pmForm" :model="pmData" :rules="rules">
                            <a-form-item name="selectPM">
                                <a-select :dropdownAlign="{ overflow: { adjustY: false } }"
                                    :dropdownStyle="{ maxHeight: '60px' }" style="width:200px"
                                    v-model:value="pmData.selectPM" show-search placeholder="选择PM" :show-arrow="false"
                                    :filter-option="filterOption" :options="pmOption">
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </template>
                    <template v-if="canSelectPM == '否'">
                        <a-input style="width:200px" disabled></a-input>
                    </template>
                </template>

            </div>
            <div class="bottom">
                <a-button type="primary" :disabled="selectedRowKeys.length < 2" @click="batchEdit">批量编辑</a-button>
                <a-button type="primary" @click="saveItem">{{ isFirstSave === false ? '保存' : '修改' }}</a-button>
            </div>


        </div>
        <DemandInfo ref="demandInfoRef"></DemandInfo>
        <emp-info v-if="isShowingEmpInfo" :isShowingEmpInfo="isShowingEmpInfo" :userDataList="userDataList" :getId="getId"
            @close-emp-info="closeEmpInfo" cancelText="取消" />
        <batchEdit :modelValue="visible" @closeModal="closeModalHandler" />
        <CustomizeInfo ref="CustomizeInfoRef" :nowStep="3" />
        <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo" :nowStep="3"
            @close-modal="closePipelineModal" />
    </div>
</template>
<script>
import { defineComponent, ref, watch, onMounted, reactive } from "vue";
import DemandInfo from "./DemandInfo.vue";
import Tooltip from "@/components/Tooltip.vue";
import {
    ClockCircleOutlined,
    EnvironmentOutlined,
    OrderedListOutlined,
    UserOutlined,
    DownCircleFilled,
    MenuOutlined,
    ScheduleOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import EmpInfo from "@/components/EmpInfo.vue";
import moment from "moment";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import batchEdit from "./batchEdit.vue"
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
export default defineComponent({
    name: "addArveList",
    props: {
        demandObj: Array,
        demandType: Number,
        consultObj: Array
    },
    components: {
        ScheduleOutlined,
        MenuOutlined,
        PipelineModal,
        DemandInfo,
        ClockCircleOutlined,
        EnvironmentOutlined,
        Tooltip,
        OrderedListOutlined,
        UserOutlined,
        DownCircleFilled,
        EmpInfo,
        batchEdit,
        CustomizeInfo,
    },
    setup(props) {
        const { $api, $EventBus } = useGlobalPropertyHook()
        onMounted(() => {
            getPMOptions()
        })
        const demandInfoRef = ref();
        // 查看项目详情
        const checkDemandDetails = (details) => {
            demandInfoRef.value.showModal(details);
        };
        const handleMoreInfoClick = (index) => {
            if (activedItemIndex.value === index) {
                activedItemIndex.value = -1;
            } else {
                activedItemIndex.value = index;
            }
        };
        // 查看项目详情
        const pipelineVisible = ref(false)
        let optType = 'newMatch'
        const pipelineInfo = ref({})
        const checkPipelineDetails = (details) => {
            pipelineInfo.value = details
            pipelineVisible.value = true
        };
        const closePipelineModal = () => {
            pipelineInfo.value = {}
            pipelineVisible.value = false
        }
        // 监听项目补充说明点击
        let activedItemIndex = ref(-1);
        const columns = [
            {
                title: "Name",
                dataIndex: "fullName",
                key: "fullName",
                ellipsis: true,
                width: 120,
                slots: { customRender: "name" }
            },
            {
                title: "Grade",
                dataIndex: "psGroup",
                key: "psGroup",
                ellipsis: true,
                width: 80,
            },
            {
                title: "Base Location",
                dataIndex: "workLocation",
                key: "workLocation",
                ellipsis: true,
                width: 120,
            },
            {
                title: "ARVE Assumption %",
                dataIndex: "arveAssumption",
                key: "arveAssumption",
                ellipsis: true,
                width: 150,
                slots: { customRender: "arveAssumption" }
            },
            {
                title: "ARVE Assumption Start Date",
                dataIndex: "arveAssumptionStartDate",
                key: "arveAssumptionStartDate",
                ellipsis: true,
                width: 200,
                slots: { customRender: "arveAssumptionStartDate" }
            },
        ]
        const tableData = ref([])
        const tableRef = ref()
        watch(() => props.consultObj, (val) => {
            tableData.value = JSON.parse(JSON.stringify(val)).map((item) => { return { ...item, arveAssumption: null, arveAssumptionStartDate: null } })
        }, {
            immediate: true,
            deep: true
        })
        const formRef = ref()
        const validateArve = (rules, value, type) => {
            if (!value[type]) {
                let string = ''
                if (type == 'arveAssumption') {
                    string = '请输入ARVE数字'
                } else {
                    let info = type
                    if (type == 'arveAssumptionStartDate') {
                        info = 'ARVE AssumptionStartDate'
                    }
                    string = `${info}为必填项`
                }
                return Promise.reject(string)
            } else if (type == 'arveAssumption') {
                const isValidNumber = (input) => {
                    const regex = /^(0$|[1-9][0-9]?|100)$/;
                    return regex.test(input);
                }
                let flag = isValidNumber(value[type])
                if (!flag) {
                    return Promise.reject('请输入0-100之间的数字')
                } else {
                    return Promise.resolve()
                }
            } else {
                return Promise.resolve()
            }
        }
        $EventBus.on("clearFinish", () => {
            tableData.value = tableData.value.map((item) => { return { ...item, arveAssumption: null, arveAssumptionStartDate: null } })
            pmData.value = {
                selectPM: undefined
            }
            isFirstSave.value = false
        })
        const selectedRowKeys = ref([])
        const onSelectChange = (value) => {
            selectedRowKeys.value = value;
        };
        const pmForm = ref()
        const pmOption = ref([])
        const pmData = ref({
            selectPM: undefined
        })
        const getPMOptions = async () => {
            let res = await $api.findMatchingPM()
            pmOption.value = res.data.map(item => {
                return {
                    ...item,
                    value: item.fullName + '/' + item.email
                }
            })
        };
        const rules = {
            selectPM: [
                {
                    required: true,
                    message: "请选择PM",
                    trigger: ["change", "blur"],
                },
            ],
        };
        const isFirstSave = ref(false)
        const saveItem = () => {
            if (canSelectPM.value == '是') {
                saveWithPM()
            } else {
                saveWithOutPM()
            }

        }
        const saveWithPM = () => {
            Promise.all([pmForm.value.validate(), formRef.value.validate()]).then(() => {
                let pm = pmOption.value.find(
                    (item) =>
                        item.fullName + "/" + item.email === pmData.value.selectPM
                );
                // 提交的数据
                let obj = {
                    pmId: pm.id,
                    consultantInfoCommandList: tableData.value,
                }
                // 展示的PM
                let pmObj = {
                    option: pmOption.value,
                    pm: pmData.value.selectPM
                }
                if (isFirstSave.value == false) {
                    message.success("保存ARVE成功,可创建匹配");
                } else {
                    message.success("匹配信息修改成功");
                }
                isFirstSave.value = true
                $EventBus.emit("finishFourStep", {
                    isContainPM: true,
                    value: JSON.parse(JSON.stringify(obj)),
                    pm: JSON.parse(JSON.stringify(pmObj))
                },
                )
            })
        }
        const saveWithOutPM = () => {
            Promise.all([formRef.value.validate()]).then(() => {
                // 提交的数据
                let obj = {
                    pmId: '',
                    consultantInfoCommandList: tableData.value,
                }
                // 展示的PM
                if (isFirstSave.value == false) {
                    message.success("保存ARVE成功,可创建匹配");
                } else {
                    message.success("匹配信息修改成功");
                }
                isFirstSave.value = true
                $EventBus.emit("finishFourStep", {
                    isContainPM: false,
                    value: JSON.parse(JSON.stringify(obj))
                })
            })
        }
        const CustomizeInfoRef = ref();
        // 查看自定义项目详情
        const checkCustomizeDetails = (details) => {
            CustomizeInfoRef.value.showModal(details);
        };
        const visible = ref(false)
        const isShowingEmpInfo = ref(false);
        const userDataList = reactive({});
        const getId = ref();
        const showEmpInfo = (id) => {
            getId.value = id;
            $api.getOneUser(id).then((res) => {
                if (res.code == "200") {
                    isShowingEmpInfo.value = true;
                    Object.assign(userDataList, res.data);
                    if (res.data.onboardDate != null) {
                        userDataList.onboardDate = moment(res.data.onboardDate).format(
                            "YYYY-MM-DD"
                        );
                    }
                }
            });
        };
        const closeEmpInfo = () => {
            isShowingEmpInfo.value = false;
        };
        const clickName = (val) => {
            showEmpInfo(val.staffId)
        }
        const batchEdit = () => {
            visible.value = true
        }
        const closeModalHandler = (type, obj) => {
            visible.value = false
            // 批量编辑确定逻辑
            if (type === 1) {
                selectedRowKeys.value.forEach((item) => {
                    tableData.value[item].arveAssumptionStartDate = obj.arveAssumptionStartDate
                    tableData.value[item].arveAssumption = obj.arveAssumption
                })

            }
        }

        const filterOption = (input, option) => {
            if (!input) return false;
            let reg = /[\s|/,|/，]*/g
            return option.value.replace(reg, '').toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0 || option.ggid.toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0;
        };
      
        const canSelectPM = ref('是')
        watch(()=>props.demandType,(val)=>{
        if(val != 2){
            canSelectPM.value = '是'
        } else {
            canSelectPM.value = '否'
        }
        },{
            immediate: true,
            deep: true
        })
        const selectPMOption = ['否', '是']
        const changeRadio = () => {
            pmData.value = {
                selectPM: undefined
            }
        }

        return {
            activedItemIndex,
            demandInfoRef,
            checkDemandDetails,
            handleMoreInfoClick,
            columns,
            tableData,
            saveItem,
            formRef,
            validateArve,
            selectedRowKeys,
            onSelectChange,
            pmForm,
            pmData,
            pmOption,
            rules,
            isShowingEmpInfo,
            closeEmpInfo,
            userDataList,
            getId,
            clickName,
            tableRef,
            visible,
            batchEdit,
            closeModalHandler,
            isFirstSave,
            checkCustomizeDetails,
            CustomizeInfoRef,
            filterOption,
            pipelineVisible,
            optType,
            pipelineInfo,
            closePipelineModal,
            checkPipelineDetails,
            canSelectPM,
            selectPMOption,
            changeRadio
        };
    },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
:deep(.ant-list-items) {
    //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
    //overflow: scroll;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        width: 10px;
        background: #d0e8ff;
        border: 1px solid #d0e8ff;
        border-radius: 5px;
    }
}

:deep(.ant-list-empty-text) {
    //height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
    width: 100%;
    display: table;
}

:deep(.ant-empty) {
    display: table-cell;
    vertical-align: middle;
}

.demand-list {
    overflow-y: auto;
    height: 150px;

    .icon {
        margin-right: 4px;
    }

    .list-item {
        padding: 0;
    }

    .padding-container {
        padding: 24px 32px 0;
    }

    .first-line {
        font-size: 18px;
        //color: #1890ff;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .demand-name {
        display: flex;

        .demand-name-text {
            //display: block;
            //overflow: hidden;
            //text-overflow: ellipsis;
            cursor: pointer;
            display: inline-block;
            margin-left: 0.41667vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            //line-height: 1.30208vw;
            font-size: .9375vw;
            color: #4d4d4d;
            font-weight: 600;
            border-bottom: 1px solid #4d4d4d;
        }

        .completetag {
            padding-top: 0.15vw;
            font-size: 0.73vw;
        }

        .ant-tag {
            margin-left: 0.6vw;
            border-radius: 1vw;
            line-height: inherit;
        }

        .num {
            display: inline-block;
            padding: 0 4px;
            //line-height: 20px;
            color: #3182CE;
            font-weight: 600;
            border: 1px solid #77CAED;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.8vw;
            margin-top: 0.2vw;
        }

        :deep(.ant-tag-blue) {
            color: #3182CE;
            background: #E9F6FE;
            border-color: #E9F6FE;
        }

    }

    .sec-line {
        color: #505050;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .more-info {
        margin: 0 32px 16px;
        display: flex;
        align-items: center;
        width: 100px;
        cursor: pointer;

        .more-info-icon {
            color: #1890ff;
            margin-right: 7px;
        }
    }

    .suplement {
        line-height: 32px;
        background: #FAFAFA;
        padding: 0 32px;
        margin-bottom: 0;

        &.un-active {
            max-height: 0;
            overflow: hidden;
        }

        &.active {
            max-height: 1000px;
            transition: 0s;
            border-top: 1px solid #CCCCCC;
        }
    }

    .key-msg-list {
        display: flex;
        align-items: center;

        .key-msg-item {
            display: flex;
            align-items: center;

            &::after {
                content: "";
                display: inline-block;
                height: 17px;
                width: 0.05vw;
                background-color: #359eff;
                margin: 0 10px;
            }

            &:last-child {
                border: none;

                &::after {
                    width: 0;
                }
            }

            .img {
                width: 1vw;
                height: 1vw;
                cursor: pointer;
            }
        }
    }

    .add-btn {
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .added-btn {
        background-color: #1890ff;
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: calc(45px / 2);
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
    }
}

.customize-list {
    overflow-y: auto;
    height: 150px;

    .icon {
        margin-right: 4px;
    }

    .list-item {
        padding: 0;
    }

    .padding-container {
        padding: 24px 32px 0;
    }

    .first-line {
        font-size: 18px;
        //color: #1890ff;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .customize-name {
        display: flex;

        .customize-name-text {
            //display: block;
            //overflow: hidden;
            //text-overflow: ellipsis;
            max-width: 180px;
            cursor: pointer;
            display: inline-block;
            margin-left: 0.41667vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            //line-height: 1.30208vw;
            font-size: .9375vw;
            color: #4d4d4d;
            font-weight: 600;
            border-bottom: 1px solid #4d4d4d;
        }

        .completetag {
            padding-top: 0.15vw;
            font-size: 0.73vw;
            color: #FDCD46;
            background: #FFFCB8;
        }

        .ant-tag {
            margin-left: 0.6vw;
            border-radius: 1vw;
            line-height: inherit;
        }

        .num {
            display: inline-block;
            padding: 0 4px;
            //line-height: 20px;
            color: #3182CE;
            font-weight: 600;
            border: 1px solid #77CAED;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.8vw;
            margin-top: 0.2vw;
        }

        :deep(.ant-tag-blue) {
            color: #3182CE;
            background: #E9F6FE;
            border-color: #E9F6FE;
        }

        .circle {
            margin-top: 0.2vw;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 0.6rem;
            background-color: #C1E0FF;
        }
    }

    .sec-line {
        color: #505050;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .more-info {
        margin: 0 32px 16px;
        display: flex;
        align-items: center;
        width: 100px;
        cursor: pointer;

        .more-info-icon {
            color: #1890ff;
            margin-right: 7px;
        }
    }

    .suplement {
        line-height: 32px;
        background: #FAFAFA;
        padding: 0 32px;
        margin-bottom: 0;

        &.un-active {
            max-height: 0;
            overflow: hidden;
        }

        &.active {
            max-height: 1000px;
            transition: 0s;
            border-top: 1px solid #CCCCCC;
        }
    }

    .key-msg-list {
        display: flex;
        align-items: center;

        .key-msg-item {
            display: flex;
            align-items: center;

            &::after {
                content: "";
                display: inline-block;
                height: 17px;
                width: 0.05vw;
                background-color: #359eff;
                margin: 0 10px;
            }

            &:last-child {
                border: none;

                &::after {
                    width: 0;
                }
            }

            .img {
                width: 1vw;
                height: 1vw;
                cursor: pointer;
            }
        }
    }

    .add-btn {
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .added-btn {
        background-color: #1890ff;
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: calc(45px / 2);
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
    }
}

:deep(.ant-list-bordered) {
    border: 0px;
}

.icon-red {
    :deep(.ant-modal-body .anticon-exclamation-circle) {
        color: #DC5E4F;
    }
}

:deep(.ant-pagination) {
    justify-content: flex-end;
}

.arveTable {
    padding: 0 20px;
    margin-top: 5px;
    width: 100%;
    height: 380px;
    overflow-y: auto
}

:deep(.ant-input-number-handler-wrap) {
    display: none !important
}

.selectPm {
    padding: 0 20px;
    width: 100%;

    .top {
        margin-bottom: 3vh;
        display: flex;
        align-items: center;

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        .redTop {
            color: red;
            font-size: 17px;
            font-weight: 400;
            margin-right: 10px;
        }

        .text {
            font-size: 17px;
            font-weight: 400;
            margin-right: 4px;
        }

        :deep(.ant-form-item-explain) {
            position: absolute;
            top: 4.5vh;
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between
    }
}

:deep(.ant-list-empty-text) {
    width: 100%;
    display: table;
}

:deep(.ant-empty) {
    display: table-cell;
    vertical-align: middle;
}

.pipeline-list {
    overflow-y: auto;
    height: 100% !important;

    .icon {
        margin-right: 4px;
    }

    .list-item {
        padding: 0;
    }

    .padding-container {
        padding: 24px 32px 0;
    }

    .first-line {
        font-size: 18px;
        //color: #1890ff;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pipeline-name {
        display: flex;
        align-items: center;

        .pipeline-name-text {
            cursor: pointer;
            display: inline-block;
            margin-left: 0.41667vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: .9375vw;
            color: #4d4d4d;
            font-weight: 600;
            border-bottom: 1px solid #4d4d4d;
        }

        .completetag {
            font-size: 0.73vw;
            color: #FFFFFF;
            background: #58B99E;
            margin-left: 0.6vw;
        }

        .numbertag {
            margin-right: 0.1667vw;
            padding: 0 0.2458vw;
            font-size: 0.625vw;
            border: 0;
            background: #C1E0FF;
            color: #FFFFFF;
        }

        .ant-tag {
            border-radius: 1vw;
            line-height: inherit;
        }

        .num {
            display: inline-block;
            padding: 0 4px;
            //line-height: 20px;
            color: #3182CE;
            font-weight: 600;
            border: 1px solid #77CAED;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.8vw;
            margin-top: 0.2vw;
        }

        :deep(.ant-tag-blue) {
            color: #3182CE;
            background: #E9F6FE;
            border-color: #E9F6FE;
        }

    }

    .sec-line {
        color: #505050;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .sec-line-icon {
        font-size: 14px;
        line-height: 22px;
        color: #3182CE;
        margin-bottom: 24px;

        .img {
            width: 17px;
            height: 17px;
            cursor: pointer;
            margin-bottom: 0.2vw;
        }
    }

    .more-info {
        margin: 0 32px 16px;
        display: flex;
        align-items: center;
        width: 100px;
        cursor: pointer;

        .more-info-icon {
            color: #1890ff;
            margin-right: 7px;
        }
    }

    .suplement {
        line-height: 32px;
        background: #FAFAFA;
        padding: 0 32px;
        margin-bottom: 0;

        &.un-active {
            max-height: 0;
            overflow: hidden;
        }

        &.active {
            max-height: 1000px;
            transition: 0s;
            border-top: 1px solid #CCCCCC;
        }
    }

    .key-msg-list {
        display: flex;
        align-items: center;

        .key-msg-item {
            display: flex;
            align-items: center;

            .key-msg-span {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &::after {
                content: "";
                display: inline-block;
                height: 17px;
                width: 0.05vw;
                background-color: #359eff;
                margin: 0 10px;
            }

            &:last-child {
                border: none;

                &::after {
                    width: 0;
                }
            }

            .img {
                width: 1vw;
                height: 1vw;
                cursor: pointer;
            }
        }
    }

    .add-btn {
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .added-btn {
        background-color: #1890ff;
        width: 35px;
        height: 35px;
        border: 2px solid #1890ff;
        box-sizing: border-box;
        border-radius: 4px;
        color: #1890ff;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: calc(45px / 2);
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
    }
}

:deep(.ant-list-bordered) {
    border: 0px;
}

:deep(.ant-pagination) {
    justify-content: flex-end;
}
</style>
