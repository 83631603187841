<template>
  <a-drawer
      class="demandModel"
    v-if="visible"
    v-model:visible="visible"
    :title="title ? title : '需求详情'"
    width="38.5vw"
    @close="cancel"
    :getContainer="getRefsAff"
  >
    <div id="demand">
      <div>
        <CardTitle class="card"> {{ demandInfo.demandName }}</CardTitle>
      </div>
      <div>
        <a-row class="demandInfoRow">
          <a-col :span="12">
            <div>
              需求编号:<p class="text">{{ demandInfo.demandNumber }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              优先级:<p class="text">{{ demandInfo.priority }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              需求部门:<p class="text">{{ demandInfo.demandDeptName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              职位进度:<p class="text">{{ demandInfo.gradeProgress? demandInfo.gradeProgress : '-'}}</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <hr style="height: 1px; border: none; border-top: 1px solid #f2f2f2" />
      <div>
        <a-row class="demandInfoRow">
          <a-col :span="12">
            <div>
              岗位名称:<p class="text">{{ demandInfo.jobName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              需求确认时间:<p class="text">{{ demandInfo.demandConfirmTime }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              人员级别:<p class="text">{{ demandInfo.staffGrade }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              工作地点:<p class="text">{{ demandInfo.workLocation }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              技能名称:<p class="text">{{ demandInfo.skillFullName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              英语能力要求:<p class="text">{{ demandInfo.englishAbility }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              期望到岗时间:<p class="text">{{
                demandInfo.expectArrivalTime
              }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              排序到岗时间:<p class="text">{{ demandInfo.sortingArrivalTime }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              RM选择资源团队:<p class="text">{{ demandInfo.rmChooseTeam }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              预计用人时长:<p class="text">{{ demandInfo.useStaffLength }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              是否可以向上跨一级别:<p class="text">{{ demandInfo.isAcrossLevel }}</p>
            </div>
          </a-col>
          <a-col :span="24">
            <div :class="['info', showMore ? '' : 'hideInfo']">
              <p>任职资格:</p>
              <div class="moreInfo">
                <div ref="myRef" :class="[showMore ? 'moreInfoPMore' : 'moreInfoP']">{{ demandInfo.qualification }}</div>
                <div class="moreInfoBtn"
                     v-show="myRef && myRef.scrollHeight - 23 > 0"
                     @click="moreInfo()">
                  {{ showMore ? "收起" : "更多" }}
                  <CaretDownOutlined
                      :rotate="showMore ? 180 : 0"
                  />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
<!--      <div>-->
<!--        <div>-->
<!--          <div :class="['info', showMore ? '' : 'hideInfo']">-->
<!--            <p>任职资格</p>-->
<!--            <p ref="myRef" class="moreInfoP">{{ demandInfo.qualification }}</p>-->
<!--          </div>-->
<!--          <div-->
<!--            class="moreInfoBtn"-->
<!--            v-show="myRef && myRef.scrollHeight - 33 > 0"-->
<!--            @click="moreInfo()"-->
<!--          >-->
<!--            {{ showMore ? "收起" : "更多" }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <hr style="height: 1px; border: none; border-top: 1px solid #f2f2f2" />
      <div>
        <a-row class="demandInfoRow">
          <a-col :span="12">
            <div>
              项目名称:<p class="text">{{ demandInfo.projectName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              行业线:<p class="text">{{ demandInfo.industryLine }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              客户名称:<p class="text">{{ demandInfo.customerName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              详细客户名称:<p class="text">{{
                demandInfo.customerNameDetail
              }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              项目类型:<p class="text">{{ demandInfo.projectType }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              项目确定性:<p class="text">{{ demandInfo.projectCertainty }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              客户服务经理(CSM):<p class="text">{{ demandInfo.csm }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              项目补充说明:<p class="projectSupplement text" :title=demandInfo.projectSupplement>{{ demandInfo.projectSupplement? demandInfo.projectSupplement : '-' }}</p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="bottom-btn" v-if="isHaveAdd">
      <a-button @click="cancel" class="cancelBtn">取消</a-button>
      <a-button
          @click="handleOk(demandInfo)"
          type="primary"
          class="addEmpBtn"
          v-if="btnVisible && nowStep === 2 && isContainConsult"
      >添加至匹配器
      </a-button>
    </div>
    <div class="bottom-btn" v-else>
      <a-button @click="cancel" class="cancelBtn">关闭</a-button>
    </div>
  </a-drawer>
  <ModalBox :class="iconRed?'icon-red':''" v-model="modelValue" :ok="handleOkModal" :content="content" :showIconCom="showIconCom" :footer="footer" />
</template>
<script>
import {defineComponent, ref} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {message} from "ant-design-vue";
import {CaretDownOutlined} from "@ant-design/icons-vue";
import CardTitle from "@/components/CardTitle.vue";
import ModalBox from "@/components/ModalBox.vue";

export default defineComponent({
  name: "DemandInfo",
  components: {ModalBox, CaretDownOutlined, CardTitle},
  props: {
    title: String,
    isHaveAdd: {
      type: Boolean,
      default: true,
    },
    nowStep:{
      type:Number
    },
    consultObj: Array,
    isContainConsult:Boolean
  },
  setup(props) {
    const visible = ref(false);
    const demandInfo = ref({});
    const { $EventBus } = useGlobalPropertyHook();
    const btnVisible = ref(true);
    let myRef = ref();
    let showMore = ref();
    const showModal = (data) => {
      demandInfo.value = data;
      visible.value = true;
    };
    const { $api } = useGlobalPropertyHook();
    const handleOk = async (data) => {
      // 新需求匹配添加
      let obj = {
        userId: props.consultObj.map((item) => { return item.staffId }),
        demandId: data.id,
      }
      if(props.consultObj.length > 1 && data.completeChannel === "TA_Team"){
        message.error("TA_Team的渠道的需求只允许匹配一个顾问！");
        return
      }
      let res = await $api.isConsultInDemand(obj)
      if (res.msg != '成功') {
        modalData.value = data
        content.value = `顾问${res.data.join('、')}已匹配过该需求，不可重复匹配`
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = true
        modelValue.value = true
        iconRed.value = true
        return
      }
      // 下面是原来的逻辑，暂时不动，期待后人的智慧
      const params = {
        demandId: data.id,
      };
      if (data.completeChannel === "TA_Team") {
        $api.isTADemandMatched(params).then((res) => {
          if (res.data) {
            content.value = '本需求（完成渠道：TA_Team）已有选定人员'
            showIconCom.value = 'ExclamationCircleOutlined'
            footer.value = true
            modelValue.value = true
            iconRed.value = true
          } else {
            $EventBus.emit("matchDemand", data);
            visible.value = false;
          }
        });
      } else {
        $api.isDemandMatched(params).then((res) => {
          if (res.data) {
            modalData.value = data
            content.value = '本需求PM已有选定人员并填写完调令，是否继续本条匹配？'
            showIconCom.value = 'ExclamationCircleOutlined'
            footer.value = false
            modelValue.value = true
          } else {
            $EventBus.emit("matchDemand", data);
            visible.value = false;
          }
        });
      }
    };

    const iconRed = ref(false)
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOkModal = () => {
      if(modalData.value) {
        $EventBus.emit("matchDemand", modalData.value);
        visible.value = false;
      }
    }

    const moreInfo = () => {
      showMore.value = !showMore.value;
    };

    const cancel = () => {
      visible.value = false;
      showMore.value = false
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      myRef,
      showMore,
      visible,
      demandInfo,
      moreInfo,
      showModal,
      handleOk,
      cancel,
      getRefsAff,
      btnVisible,
      modelValue,
      content,
      showIconCom,
      iconRed,
      footer,
      handleOkModal,
    };
  },
});
</script>

<style lang="less" scoped>
.footerBtn {
  text-align: center;
  margin: 5px;

  .cancelBtn,
  .addEmpBtn {
    margin: 10px 15px;
  }
}

#demand {
  position: relative;
  background: #ffffff;
  margin: 1.25vw 0px 10px 1.25vw;

  .card {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    &::before {
      top: 0.1vw;
    }
  }
  .demandInfoRow {
    margin-top: 1vw;
    color: rgba(77, 77, 77, 0.6);
    white-space: pre;
    margin-bottom: 10px !important;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .text {
    //margin-left: 16px;
    color: #4D4D4D;
    white-space: initial;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0.2vw 0 0.83vw 0.2vw !important;
  }

  //> div:nth-of-type(1) {
  //  margin: 0 60px;
  //  display: flex;
  //  flex-direction: row;
  //
  //  > div:nth-of-type(1n + 0) {
  //    font-size: 25px;
  //    font-weight: 600;
  //    width: 540px;
  //  }
  //}

  //> div:nth-of-type(2),
  //> div:nth-of-type(3),
  //> div:nth-of-type(4),
  //> div:nth-of-type(5) {
  //  margin: 0 60px;
  //  display: flex;
  //  flex-direction: row;
  //
  //  > div {
  //    flex: 1;
  //  }
  //
  //  > div:nth-of-type(1n + 0) {
  //    width: 610px;
  //    font-weight: 500;
  //    flex-direction: column;
  //
  //    > div:nth-of-type(1n + 0) {
  //      margin: 5px 0;
  //
  //      > p:nth-of-type(odd) {
  //        margin-bottom: 0px;
  //      }
  //
  //      > p:nth-of-type(even) {
  //        font-weight: 500;
  //        color: #1890ff;
  //        min-height: 25px;
  //      }
  //
  //      > span:nth-of-type(1) {
  //        display: inline-block;
  //        width: 20px;
  //      }
  //
  //      > span:nth-of-type(2) {
  //        color: #1890ff;
  //      }
  //    }
  //  }
  //}
  .projectSupplement {
    width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }
  .info {
    overflow: hidden;
    //width: 1150px;
    width: 100%;
  }

  .hideInfo {
    height: 50px;
  }

  .moreInfoBtn {
    color: #3182CE;
    text-align: center;
    width: 30px;
    cursor: pointer;
    margin-left: 1vw;
  }
  .moreInfoP {
    //line-height: 170%;

    width: 85%;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .moreInfoPMore {
    width: 85%;
    overflow: auto;
    //white-space: initial;
    //text-overflow: ellipsis;
    //-webkit-box-orient: vertical;
    //display: -webkit-box;
    //white-space: initial
  }
  p{
    margin-bottom: 0
  }
  .moreInfo {
    display: flex;
    color: #4D4D4D;
  }
}

</style>
<style>
.demandModel .ant-modal-body {
  padding: 0 !important;
}
</style>
