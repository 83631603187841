import { ref} from "vue";

import { useGlobalPropertyHook } from '@/hooks/common'
// 搜索条件时候存在更多
const extraListHandler = () => {
    const { $api } = useGlobalPropertyHook()

    const moreBtnVisible = ref(false)

    const isShowFormSortBox = ref(false)

    let isShowMoreList = ref(false)

    const checkList = ref([])

    const queryList = ref([])

    const showMoreForm = (data) => {
        isShowMoreList.value = data
    }
    const closeMoreForm = () => {
        isShowMoreList.value = false
    }

    const openFormSortBox = () => {
        isShowFormSortBox.value = true
    }

    const closeModal = () => {
        isShowFormSortBox.value = false
    }

    const queryFormList = async (data = { isQueryDefault: 0, isQuery: true }, firstMenu = 'resourceManagement', secondMenu ='demandMatchingPerson', index = 4) => {
        let res = await $api.queryFilterSort({
            firstMenu: firstMenu,
            secondMenu: secondMenu,
            isQueryDefault: data.isQueryDefault
        })
        if (data.isQuery) {
            queryList.value = res.data.filter((item) => {
                return item.isDeleted === '0'
            })
            queryList.value.length > index ? moreBtnVisible.value = true : moreBtnVisible.value = false
            checkList.value = [...queryList.value]
        } else {
            checkList.value = res.data.filter((item) => {
                return item.isDeleted === '0'
            })
        }
    }
    return {
        moreBtnVisible,
        isShowFormSortBox,
        isShowMoreList,
        showMoreForm,
        closeMoreForm,
        openFormSortBox,
        closeModal,
        checkList,
        queryFormList,
        queryList
    }
}

export { extraListHandler }