<template>
  <section class="customize-list">
    <a-list
        size="small"
        bordered
        :data-source="customizeList"
        :pagination="customizeList.length > 0 ? pagination : false"
        id="customize-list-content"
        class="customize-list-content"
    >
      <template #renderItem="{ item }">
        <a-list-item class="list-item ant-list-item-no-flex" @click.stop="">
          <a-row class="padding-container">
            <a-col :span="22">
              <a-row>
                <a-col :span="10" style="padding-right: 3vw">
                  <p
                      class="first-line customize-name"
                  >
                    <span class="num" @click="checkCustomizeDetails(item)">{{ item.demandNumber }}</span>
                    <Tooltip :title=item.demandName>
                      <span class="customize-name-text" @click="checkCustomizeDetails(item)">{{item.demandName }}</span>
                    </Tooltip>
                    <a-tag color="yellow" v-show="item.type" class="completetag">
                      {{ item.type === '1'? '自定义' : '' }}
                    </a-tag>
                    <Tooltip trigger="hover" :title=item.mateNumberTitle>
                      <a-tag class="numbertag">
                        {{ item.mateNumber }}
                      </a-tag>
                    </Tooltip>
                    <Tooltip trigger="hover" :title=item.transferOrderNumberTitle>
                      <a-tag class="numbertag">
                        {{ item.transferOrderNumber }}
                      </a-tag>
                    </Tooltip>
                  </p>
                  <ul class="key-msg-list sec-line">
                    <li class="key-msg-item">
                      <Tooltip title="人员级别">
                        <img src="@/assets/iconfont/level.png" class="icon img"/>
                      </Tooltip>
                      {{ item.staffGrade }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="期望到岗时间">
                        <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img"/>
                      </Tooltip>
                      {{ item.expectArrivalTime }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="工作地点">
                        <EnvironmentOutlined class="icon" />
                      </Tooltip>
                      {{ item.workLocation }}
                    </li>
                    <li class="key-msg-item">
                      <Tooltip title="项目确定性">
                        <img src="@/assets/images/customize-Certainty.png" class="icon img"/>
                      </Tooltip>
                      {{ item.projectCertainty }}
                    </li>
                  </ul>
                </a-col >
                <a-col :span="6">
                  <p class="first-line">
                    <Tooltip :title=item.customerName>
                      客户名称：{{ item.customerName }}
                    </Tooltip>
                  </p>
                  <ul class="key-msg-list sec-line" style="color: #3182CE;margin-left: 0.2vw">
                    <li class="key-msg-item">
                      <Tooltip title="末级需求部门">
                        <img src="@/assets/images/demandDeptName.png" class="icon img"/>
                      </Tooltip>
                      <i class="highest" style="margin-top: 0.1vw">{{ item.demandDeptName }}</i>
                    </li>
                  </ul>
                </a-col>
                <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                  <p class="first-line">
                    <Tooltip :title=item.customerNameDetail>
                      详细客户名称：{{ item.customerNameDetail }}
                    </Tooltip>
                  </p>
                  <p class="sec-line" style="color: #3182CE">
                    <Tooltip title="客户服务经理(CSM)">
                      <UserOutlined class="icon"/>
                    </Tooltip>
                    <Tooltip :title="item.csm">
                      {{ item.csm.length > 50 ? item.csm.slice(0,40) + '......' : item.csm }}
                    </Tooltip>
                  </p>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="1" style="margin-bottom: 1vw">
              <div
                  class="add-btn"
                  @click="add(item)"
                  v-show="item.id !== activeRow.id && ![0,1].includes(nowStep)"
              >
                <SwapOutlined />
              </div>
              <div
                  class="added-btn"
                  @click="removeAdded(item)"
                  v-show="item.id === activeRow.id && ![0,1].includes(nowStep)"
              >
                <CheckOutlined style="color: white; font-size: 14px; margin-top: 0.2vw;" />
              </div>
            </a-col>
            <a-col :span="1" style="margin-bottom: 1vw">
              <div
                  class="add-btn"
                  @click="editCustomizeClick(item)"
                  v-show="![0,1].includes(nowStep)"
              >
                <CopyOutlined />
              </div>
            </a-col>
          </a-row>
        </a-list-item>
      </template>
    </a-list>
  </section>
  <CustomizeInfo ref="CustomizeInfoRef" :nowStep="nowStep" :consultObj="consultObj"/>
  <ModalBox :class="iconRed?'icon-red':''" v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" :footer="footer" />
</template>

<script>
import {
  CheckOutlined, CopyOutlined,
  EnvironmentOutlined,
  SwapOutlined, UserOutlined
} from "@ant-design/icons-vue";
import Tooltip from "@/components/Tooltip.vue";
import { onMounted, ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import ModalBox from "@/components/ModalBox.vue";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import moment from "moment";
let queryCache = null;
export default {
  name: "customizeList",
  components: {
    CopyOutlined,
    CustomizeInfo,
    ModalBox,
    UserOutlined,
    EnvironmentOutlined,
    SwapOutlined,
    CheckOutlined,
    Tooltip,
  },
  props: {
    nowStep:{
      type:Number
    },
    consultObj: Array
  },
  emits: ["customizeCreate"],
  setup(props,{ emit }) {
    const iconRed = ref(false)
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const CustomizeInfoRef = ref();
    const customizeList = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        getCustomizeListData(queryCache);
        // 回到页面顶端
        document.getElementById("customize-list-content").parentElement.scrollTop = 0
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        getCustomizeListData(queryCache);
      },
    });

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.value.current = 1;
      }
    };

    // 获取列表数据
    const getCustomizeListData = (queryData) => {
      queryCache = queryData ? queryData : {};
      const data = Object.assign(
          { ...queryData, type:'1' },
          {
            pageSize: pagination.value.pageSize,
            pageIndex: pagination.value.current,
          }
      );
      $api.searchDemandByCondition(data).then((res) => {
        customizeList.value = res.data.resource.map(item => {
          return {
            ...item,
            csm: item.csm?.substring(0, item.csm?.indexOf('(')) + ' ' + item.csm?.substring(item.csm?.indexOf('(')),
            mateNumberTitle: '已匹配' + item.mateNumber + '人',
            transferOrderNumberTitle: '已有调令' + item.transferOrderNumber + '个',
            operationTime: moment(item.operationTime).format("YYYY-MM-DD HH:mm:ss"),
            createTime : moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          }
        });
        pagination.value.total = res.data.total;
      });
    };

    // 查看项目详情
    const checkCustomizeDetails = (details) => {
      CustomizeInfoRef.value.showModal(details);
    };
    onMounted(() => {
      resetCurrent();
    });

    // onActivated(() => {
    //   getCustomizeListData(queryCache);
    // });

    const activeRow = ref({});
    const addFunc = (data) => {
      activeRow.value = data;
      $EventBus.emit("matchCustomize", data);
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOk = () => {
      modalData.value?addFunc(modalData.value):'';
    }
    const add = async (data) => {
      let obj = {
        userId: props.consultObj.map((item) => { return item.staffId }),
        demandId: data.id,
      }
      let res = await $api.isConsultInDemand(obj)
      if (res.msg != '成功') {
        modalData.value = data
        content.value = `顾问${res.data.join('、')}已匹配过该需求，不可重复匹配`
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = true
        modelValue.value = true
        iconRed.value = true
        return
      }
      const params = {
        demandId: data.id,
      };
      $api.isDemandMatched(params).then((res) => {
        if (res.data) {
          modalData.value = data
          content.value = '本需求PM已有选定人员并填写完调令，是否继续本条匹配？'
          showIconCom.value = 'ExclamationCircleOutlined'
          footer.value = false
          modelValue.value = true
        } else {
          addFunc(data);
        }
      });
    };

    const removeAdded = () => {
      activeRow.value = {};
      $EventBus.emit("matchCustomize", {});
    };
    $EventBus.on("matchCustomize", (data) => {
      activeRow.value = data;
    });
    const resetMatch = () => {
      activeRow.value = {};
      pagination.value.current = 1;
    };

    const editCustomizeClick = (item) => {
      emit("customizeCreate",item,'edit');
    }

    return {
      customizeList,
      pagination,
      CustomizeInfoRef,
      resetCurrent,
      getCustomizeListData,
      checkCustomizeDetails,
      add,
      removeAdded,
      activeRow,
      resetMatch,
      modelValue,
      content,
      showIconCom,
      iconRed,
      footer,
      handleOk,
      editCustomizeClick,
    };
  },
}
</script>

<style scoped lang="less">
.customize-list {
  overflow-y: auto;
  height: 100% !important;

  .customize-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 17vw);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    padding: 24px 32px 0;
  }

  .first-line {
    font-size: 18px;
    //color: #1890ff;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customize-name {
    display: flex;
    align-items: center;

    .customize-name-text {
      //display: block;
      //overflow: hidden;
      //text-overflow: ellipsis;
      cursor: pointer;
      display: inline-block;
      margin-left: 0.41667vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //line-height: 1.30208vw;
      font-size: .9375vw;
      color: #4d4d4d;
      font-weight: 600;
      border-bottom: 1px solid #4d4d4d;
    }
    .completetag {
      font-size: 0.73vw;
      color: #FDCD46;
      background: #FFFCB8;
      margin-left: 0.6vw;
    }

    .numbertag {
      margin-right: 0.1667vw;
      padding: 0 0.2458vw;
      font-size: 0.625vw;
      border: 0;
      background: #C1E0FF;
      color: #FFFFFF;
    }

    .ant-tag {
      border-radius: 1vw;
      line-height: inherit;
    }

    .num{
      display: inline-block;
      padding: 0 4px;
      //line-height: 20px;
      color: #3182CE;
      font-weight: 600;
      border: 1px solid #77CAED;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.8vw;
      margin-top: 0.2vw;
    }

    :deep(.ant-tag-blue) {
      color: #3182CE;
      background: #E9F6FE;
      border-color: #E9F6FE;
    }

    .circle {
      margin-top: 0.2vw;
      width: 1vw;
      height: 1vw;
      border-radius: 50%;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 0.5vw;
      background-color: #C1E0FF;
    }
  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .more-info {
    margin: 0 32px 16px;
    display: flex;
    align-items: center;
    width: 100px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .suplement {
    line-height: 32px;
    background: #FAFAFA;
    padding: 0 32px;
    margin-bottom: 0;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: 0s;
      border-top: 1px solid #CCCCCC;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 0.05vw;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }
      .img {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .added-btn {
    background-color: #1890ff;
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
:deep(.ant-list-bordered) {
  border: 0px;
}
.icon-red{
  :deep(.ant-modal-body .anticon-exclamation-circle){
    color: #DC5E4F;
  }
}
:deep(.ant-pagination) {
  justify-content: flex-end;
}
</style>
