<template>
  <section class="app-container">
    <div class="backE">
      <div class="new-match">新建匹配</div>
      <span @click="trunBackMatchListing" class="goback underline">
        <LeftOutlined class="icon" />
        <span class="back">返回匹配列表</span>
      </span>
    </div>

    <!-- 第一步添加顾问的搜索框 -->
    <TopFilterC @search="handleSearchC" ref="consultantFilterRef" v-show="nowStep === 0 && demandType === -1"
      @getRowHeight="getRowHeight" />

    <!-- 第二、三步北森需求的搜索框 -->
    <TopFilter @search="handleSearch" ref="damandFilterRef" v-show="[1, 2].includes(nowStep) && demandType === 1"
      @getRowHeight="getRowHeight" />

    <!-- 自定义需求 -->
    <TopFilterCustomize @search="handleSearchCustomize" ref="customizeFilterRef"
      v-show="[1, 2].includes(nowStep) && demandType === 3 && !isCustomizeCreate" @customizeCreate="customizeCreateClick"
      @getRowHeight="getRowHeight" :nowStep="nowStep" :customizeOps="customizeOps" />

    <!-- pipeline需求筛选项 -->
    <TopFilterPipeline v-show="[1, 2].includes(nowStep) && demandType === 2" ref="pipelineFilterRef"
      @search="handleSearchPipeline" @getRowHeight="getRowHeight" />

    <a-row :class="[!isCustomizeCreate ? 'bottom-content' : 'bottom-customise', nowStep === 3 ? 'moreHeight' : '']">

      <a-col :span="18" class="list-box" v-show="nowStep === 0 && demandType === -1">
        <!-- 顾问匹配阶段的右侧表格 -->
        <ConsultantList ref="consultantListRef" :contentStyleObj="contentStyleObj" />
      </a-col>

      <a-col :class="[nowStep === 3 ? 'list-EndBox' : 'list-box']" :span="18" v-show="nowStep !== 0">
        <!-- 北森需求右侧表格 -->
        <div v-show="demandType === 1 && nowStep != 3">
          <DemandList :nowStep="nowStep" ref="damandListRef" :contentStyleObj="contentStyleObj"
            :consultObj="consultObj" />
        </div>

        <!-- 自定义需求右侧表格 -->
        <div v-show="demandType === 3 && isCustomizeCreate == false && nowStep != 3">
          <customize-list :nowStep="nowStep" ref="customizeListRef" :consultObj="consultObj"
            @customizeCreate="customizeCreateClick" />
        </div>
        <div v-show="demandType === 3 && isCustomizeCreate && nowStep != 3">
          <customize-create :nowStep="nowStep" :customizeFormData="customizeFormData" @handle-close="customizeCreateClose"
            :customizeOps="customizeOps" :isCustomizeCreate="isCustomizeCreate" />
        </div>

        <!-- pipeline需求右侧表格 -->
        <div v-show="demandType === 2 && nowStep != 3">
          <PipelineList ref="pipelineListRef" :nowStep="nowStep" />
        </div>

        <!-- 第四步 -->
        <div v-show="nowStep === 3">
          <addArvevList ref="addArevList" :demandObj="demandObj" :consultObj="consultObj" :demandType="demandType" />
        </div>
      </a-col>

      <a-col :span="6" class="matchor-box">
        <!-- 右侧匹配器 -->
        <!-- stepChange 步骤变化调用 第一个参数是当前进行到了第几步 第二个是当前是什么需求 -->
        <Matcher @reset-match="resetMatch" @stepChange="stepChange" @getCustomizeData="handleSearchCustomize"
          @handerSaveCustomize="handerSaveCustomize" :isCustomizeCreate="isCustomizeCreate" @getPipelineData="handleSearchPipeline"></Matcher>
      </a-col>

    </a-row>
  </section>
</template>
<script>
import { defineComponent, ref, computed, onMounted, nextTick, onUnmounted, reactive } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import TopFilter from "./components/TopFilter.vue";
import TopFilterC from "./components/TopFilterC";
import DemandList from "./components/DemandList.vue";
import ConsultantList from "./components/ConsultantList.vue";
import Matcher from "./components/Matcher.vue";
import addArvevList from "./components/addArvevList.vue"
import { LeftOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { useTableHeight, } from "@/utils/common";
import TopFilterCustomize from "@/views/home/ResourceManagement/Match/newMatch/components/TopFilterCustomize.vue";
import CustomizeList from "@/views/home/ResourceManagement/Match/newMatch/components/customizeList.vue";
import CustomizeCreate from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeCreate.vue";
import { message } from "ant-design-vue";
import TopFilterPipeline from "@/views/home/ResourceManagement/Match/newMatch/components/TopFilterPipeline.vue";
import PipelineList from "@/views/home/ResourceManagement/Match/newMatch/components/PipelineList.vue";
export default defineComponent({
  name: "newMatch",
  components: {
    PipelineList,
    TopFilterPipeline,
    CustomizeCreate,
    CustomizeList,
    TopFilterCustomize,
    TopFilter,
    TopFilterC,
    DemandList,
    ConsultantList,
    Matcher,
    LeftOutlined,
    addArvevList
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const router = useRouter();
    const damandFilterRef = ref();
    const consultantFilterRef = ref();
    const damandListRef = ref();
    const consultantListRef = ref();
    const pipelineListRef = ref()

    // 目前匹配器在第几步了
    let nowStep = ref(0);
    // 选择的是什么需求（北森是1,pipeLine是2,自定义需求是3
    let demandType = ref(-1);

    const rowHeight = ref(0)
    const customizeFilterRef = ref()
    const customizeListRef = ref()
    const pipelineFilterRef = ref()

    // 需求匹配搜索
    const handleSearch = (data) => {
      data.reset = true;
      damandListRef.value.resetCurrent(data);
      damandListRef.value.getDemandListData(data);
    };

    // 顾问匹配搜索
    const handleSearchC = (data) => {
      data.reset = true;
      consultantListRef.value.resetCurrent(data);
      consultantListRef.value.getConsultantListData(data);
    };

    //自定义需求搜索
    const handleSearchCustomize = (data) => {
      if (data !== 3) data.reset = true;
      if(data) {
        customizeListRef.value.resetCurrent(data);
        customizeListRef.value.getCustomizeListData(data);
      } else {
        customizeFilterRef.value.resetForm();
      }
    };

    // pipeline搜索
    const handleSearchPipeline = (data) => {
      //if (data !== 2) data.reset = true;
      if(data) {
        pipelineListRef.value.resetCurrent(data);
        pipelineListRef.value.getPipelineListData(data);
      } else {
        pipelineFilterRef.value.resetForm();
      }
    };

    //自定义需求新建
    const isCustomizeCreate = ref(false)
    const customizeFormData = ref({})
    const customizeCreateClick = (data, type) => {
      $EventBus.emit("matchCustomize", {});
      getCustomizeDictionary()
      if (type === 'add') {
        customizeFormData.value = {}
        customizeFormData.value.type = 'add'
      } else {
        customizeFormData.value.type = 'edit'
        Object.assign(customizeFormData.value, data)
      }
    };
    const customizeCreateClose = () => {
      customizeFormData.value = {}
      isCustomizeCreate.value = false
    }

    // 获取第三步自定义需求筛选框
    const customizeOps = reactive({
      requirementNameOps: [],
      departmentLevelOps: [],
      demandDeptNameOps: [],
      demandPositionOps: [],
      staffGradeOps: [],
      workLocationOps: [],
      csmOps: []
    })
    const getCustomizeSelectOptions = () => {
      $api.getRmPowerInfo().then((res) => {
        customizeOps.requirementNameOps = res.data.account
        customizeOps.departmentLevelOps = res.data.orgThreeTwoList
        customizeOps.demandDeptNameOps = res.data.orgFourOneOne
      });
    };
    const getCustomizeDictionary = async () => {
      const result = await Promise.all([$api.getDictionaryList({ params: { dictType: "role,city,grade", }, }), $api.authorizedPerson()])
      customizeOps.demandPositionOps = result[0].data.role
      customizeOps.staffGradeOps = result[0].data.grade
      customizeOps.workLocationOps = result[0].data.city
      customizeOps.csmOps = result[1].data.map(item => {
        return {
          ...item,
          value: item.full_name + '（' + item.email + '）'
        }
      })
      isCustomizeCreate.value = true
    }
    const handerSaveCustomize = (data) => {
      if (isCustomizeCreate.value) {
        data.csmId = customizeOps.csmOps.filter(item => item.value.trim() === data.csm.trim())[0]?.ggid
        data.id = undefined
        $api.customAdded(data).then((res) => {
          demandObj.value = [res.data]
          $EventBus.emit("matchCustomize", JSON.parse(JSON.stringify(res.data)));
          message.success("新建自定义需求成功");
        })
      }
    }

    const trunBackMatchListing = () => {
      router.push("/matchingList");
    };

    // 完成所有步骤回到第一步清空操作
    const resetMatch = () => {
      // consultantListRef.value.resetMatch();
      // consultantFilterRef.value.resetForm();
      // damandListRef.value.resetMatch();
      // damandFilterRef.value.resetForm();
    };

    const getRowHeight = () => {
      nextTick(() => {
        rowHeight.value =
          useTableHeight(false, ".bottom-content", 0,
            undefined, 1)
      })
    };

    const contentStyleObj = computed(() => {
      return {
        height: rowHeight.value + 'px'
      }
    })
    window.addEventListener("resize", getRowHeight);
    onMounted(() => {
      getRowHeight()
      getCustomizeSelectOptions()
      consultantListRef.value.resetCurrent({ jobStatus: ["1"], withJTP: '0', skillName: [] })
    })
    onUnmounted(() => {
      window.removeEventListener("resize", getRowHeight);
    })
    const addArevList = ref()
    const demandObj = ref([])
    const consultObj = ref([])
    // stepChange 步骤变化调用 第一个参数是当前进行到了第几步 第二个是当前是什么需求, 第三个是添加需求里
    // 北森type:1, pipeLine需求:2,自定义需求:3
    const stepChange = (step, type, info = [], consultInfo = [], isRefresh) => {
      nowStep.value = step
      demandType.value = type
      // 从需求步跳转到选择需求渠道
      if (step === 0) {
        demandType.value = -1
      }
      if (step === 1) {
        isCustomizeCreate.value = false
        // 切换回 北森需求 && 清空北森已选的
        if (type === 1) {
          damandListRef.value.removeAdded()
        } else if (type === 3) {
          customizeListRef.value.removeAdded()
        } else if (type === 2) {
          pipelineListRef.value.removeAdded()
        }
      }
      if (step === 2) {
        consultObj.value = consultInfo
        if (type === 3) {
          customizeListRef.value.resetCurrent();
          customizeListRef.value.getCustomizeListData();
        }
        // isRefresh为true的时候则第四步匹配进入到了【该需求已匹配完成，请重新选择需求！】状态
        // 需要返回到第三步刷新表格
        if (isRefresh) {
          if (type == 1) {
            damandListRef.value.removeAdded()
            damandFilterRef.value.handleFormChange()
          }
          if (type == 3) {
            customizeListRef.value.removeAdded()
            customizeFilterRef.value.handleFormChange()
          }
          if (type == 2) {
            pipelineListRef.value.removeAdded()
            pipelineFilterRef.value.handleFormChange()
          }
        }
      }
      if (step === 3) {
        demandObj.value = [info]
        consultObj.value = consultInfo
        addArevList.value.selectedRowKeys = []
        //从需求步跳转到确认匹配信息
        if (type === 3) {
          isCustomizeCreate.value = false
        }
      }
    }

    return {
      damandFilterRef,
      consultantFilterRef,
      damandListRef,
      consultantListRef,
      nowStep,
      demandType,
      handleSearch,
      handleSearchC,
      trunBackMatchListing,
      resetMatch,
      rowHeight,
      contentStyleObj,
      getRowHeight,
      customizeFilterRef,
      handleSearchCustomize,
      customizeListRef,
      customizeCreateClick,
      isCustomizeCreate,
      customizeFormData,
      stepChange,
      customizeCreateClose,
      customizeOps,
      getCustomizeSelectOptions,
      getCustomizeDictionary,
      handerSaveCustomize,
      demandObj,
      consultObj,
      addArevList,
      pipelineFilterRef,
      handleSearchPipeline,
      pipelineListRef,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  //height: calc(100% - 25px);
  background: #ffffff;
  border-radius: 8px;
  padding: 0 !important;

  .bottom-content {
    padding: 0 1vw;
    height: calc(100% - 136px);

    .list-box {
      height: 100%;
      border: 1px solid rgba(0, 0, 0, .08);
      box-shadow: 0 0 0.52083vw rgba(0, 0, 0, .08);
      border-radius: 5px;
      //border-right: 3px solid rgba(0, 0, 0, 0.05);
    }

    .list-EndBox {
      height: 100%;
      border: 1px solid rgba(0, 0, 0, .08);
      box-shadow: 0 0 0.52083vw rgba(0, 0, 0, .08);
      border-radius: 5px;
      overflow-y: scroll
    }
  }

  .bottom-customise {
    padding: 0 1vw;
    height: calc(100% - 40px);

    .list-box {
      height: 100%;
      border: 1px solid rgba(0, 0, 0, .08);
      box-shadow: 0 0 0.52083vw rgba(0, 0, 0, .08);
      border-radius: 5px;
      overflow-y: auto;
    }
  }

  .moreHeight {
    height: 93% !important;
  }

  .matchor-box {
    height: 100%;
    overflow: scroll;
    border: 1px solid rgba(0, 0, 0, .08);
    box-shadow: 0 0 0.52083vw rgba(0, 0, 0, .08);
    max-width: 24.4%;
    margin-left: 0.5vw;
    border-radius: 5px;
  }
}

.backE {
  display: flex;
  height: 40px;
  background: #f7fafc;
  align-items: center;
  justify-content: space-between;

  .icon {
    font-size: 15px;
    cursor: pointer;
  }

  .back {
    font-size: 16px;
    cursor: pointer;
    margin-right: 1.25vw
  }

  .new-match {
    color: #3182CE;
    margin-left: 1vw;
    border-bottom: 2px solid #3182CE;
    padding: 0.5vw 2vw;
  }
}
</style>
