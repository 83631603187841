<template>
  <span :class="`card-title  ${size}`"
    ><slot>{{ title }}</slot></span
  >
</template>

<script>
export default {
  name: "CardTitle",
  props: {
    title: String,
    size: String,
  },
};
</script>

<style lang="less" scoped>
.card-title {
  padding-left: 16px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #2d3748;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 20px;
    background: #3182ce;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  &.large {
    padding-left: 18px;
    font-size: 20px;
    &::before {
      width: 10px;
      height: 26px;
    }
  }
}
</style>
