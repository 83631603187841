<template>
    <div class="addArveListBatchEdit" ref="addArveListBatchEdit">
        <a-modal width="33vw" :getContainer="getRefsAff" :visible="modelValue" title="请填写ARVE数据" @cancel="closeModal"
            :maskClosable="false">
            <a-form ref="formRef" :model="formData">
                <div class="batchItem">
                    <div class="batchTitle">ARVE Assumption %<span>*</span></div>
                    <a-form-item name="arveAssumption" :rules="[
                        {
                            validator: (info) => validateArve(info, formData.arveAssumption, 'arveAssumption'),
                            trigger: ['change', 'blur'],
                        }]">
                        <a-input style="width:200px" v-model:value="formData.arveAssumption" placeholder="请输入" />
                    </a-form-item>
                </div>
                <div class="batchItem">
                    <div class="batchTitle">ARVE Assumption Start Date <span>*</span></div>
                    <a-form-item name="arveAssumptionStartDate" :rules="[
                        {
                            validator: (info) => validateArve(info, formData.arveAssumptionStartDate, 'arveAssumptionStartDate'),
                            trigger: ['change', 'blur'],
                        }]">
                        <a-date-picker valueFormat="YYYY-MM-DD" style="width:200px"
                            v-model:value="formData.arveAssumptionStartDate" placeholder="请输入" />
                    </a-form-item>
                </div>
            </a-form>
            <template #footer>
                <div class="footerBtn">
                    <a-button @click="closeModal">取消</a-button>
                    <a-button @click="confirmSure" type="primary">确定
                    </a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    props: {
        modelValue: Boolean,
    },
    name: "addArveListBatchEdit",
    setup(props, { emit }) {
        const closeModal = () => {
            formData.value = {
                arveAssumption: '',
                arveAssumptionStartDate: ''
            }
            emit('closeModal', -1)
        }
        const formData = ref({
            arveAssumption: '',
            arveAssumptionStartDate: ''
        })
        const validateArve = (rules, value, type) => {
            if (!value) {
                let string = ''
                if (type == 'arveAssumption') {
                    string = '请输入ARVE数字'
                } else {
                    let info = type
                    if (type == 'arveAssumptionStartDate') {
                        info = 'ARVE AssumptionStartDate'
                    }
                    string = `${info}为必填项`
                }
                return Promise.reject(string)
            } else if (type == 'arveAssumption') {
                const isValidNumber = (input) => {
                    const regex = /^(0$|[1-9][0-9]?|100)$/;
                    return regex.test(input);
                }
                let flag = isValidNumber(value)
                if (!flag) {
                    return Promise.reject('请输入0-100之间的数字')
                } else {
                    return Promise.resolve()
                }
            } else {
                return Promise.resolve()
            }
        }
        const formRef = ref()
        const confirmSure = () => {
            formRef.value.validate().then(() => {
                emit('closeModal', 1, formData.value)
                formData.value = {
                    arveAssumption: '',
                    arveAssumptionStartDate: ''
                }
            })
        }
        const getRefsAff = () => {
            let app = document.getElementById("main-page");
            if (app) {
                return app;
            }
        };
        return {
            closeModal,
            validateArve,
            formData,
            confirmSure,
            formRef,
            getRefsAff
        }
    },
});
</script>
<style scoped lang="less">
.batchItem {
    display: flex;

    .batchTitle {
        font-weight: 600;
        width: 14vw;

        span {
            color: red
        }
    }
}

:deep(.ant-input-number-handler-wrap) {
    display: none !important
}

@import "~@/style/aModal.less";

:deep(.ant-modal-body) {
    max-height: 300px;
    overflow: auto;
    font-size: 16px;

    &::-webkit-scrollbar {
        width: 8px;
        height: 13px;
    }

    &::-webkit-scrollbar-thumb {
        width: 8px;
        height: 13px;
        background: #d0e8ff;
        border: 1px solid #000;
        border-radius: 5px;
    }

    p {
        position: relative;
        line-height: 24px;
    }

    .anticon {
        position: absolute;
        top: 2px;
        left: 0;
        margin-right: 8px;
        font-size: 20px;
    }

    .anticon-exclamation-circle {
        color: #fdcd46;
    }

    .anticon-close-circle {
        color: #DC5E4F;
    }
}</style>
  