<template>
  <section class="top-filter">
    <FilterLayout
        @doReset="resetForm"
        @doSearch="handleFormChange"
        ref="filterLayoutRef"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList"
    >
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <template v-for="(item,index) in queryList" :key="index">
            <template v-if="index<=showNuber">
              <a-form-item :label="item.code" :name="item.inputDataType" class="rms-form-item">
                <template v-slot:label v-if="item.type==='2'&&(item.inputDataType==='completeChannel')">
                  <span>{{ item.code }}</span>
                  <a-popover placement="topLeft">
                    <QuestionCircleOutlined :style="{ color: '#3182ce','margin-bottom': '0.15vw' }"/>
                    <template #content>
                      TA 渠道的需求只允许匹配一个顾问
                    </template>
                  </a-popover>
                </template>
                <template v-slot:label v-else>
                  <span>{{ item.code }}</span>
                </template>
                <template v-if="item.type==='2'">
                  <a-select
                      v-model:value="formState[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                  >
                  </a-select>
                </template>
                <template v-if="item.type==='3'">
                  <a-input
                      v-model:value="formState[item.inputDataType]"
                      placeholder="项目名称/技能/岗位/需求编号"
                      allowClear
                      autocomplete="off"
                  />
                </template>
                <template v-if="item.type==='5'">
                  <SelectWithAll
                      v-auth="['system:task:grade']"
                      v-model:value="formState[item.inputDataType]"
                      :options="getOptions(item.inputDataType)"
                      placeholder="请选择"
                      allowClear
                      show-search
                      :maxTagCount="1"
                      mode="multiple"
                      showArrow
                  >
                  </SelectWithAll>
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index>showNuber">
                <a-form-item :label="item.code" :name="item.inputDataType" class="rms-form-item">
                  <template v-slot:label v-if="item.type==='2'&&(item.inputDataType==='completeChannel')">
                    <span>{{ item.code }}</span>
                    <a-popover placement="topLeft">
                      <QuestionCircleOutlined :style="{ color: '#3182ce','margin-bottom': '0.15vw' }"/>
                      <template #content>
                        TA 渠道的需求只允许匹配一个顾问
                      </template>
                    </a-popover>
                  </template>
                  <template v-slot:label v-else>
                    <span>{{ item.code }}</span>
                  </template>
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        placeholder="项目名称/技能/岗位/需求编号"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-auth="['system:task:grade']"
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                        :maxTagCount="1"
                        mode="multiple"
                        showArrow
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
    </FilterLayout>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, nextTick } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {QuestionCircleOutlined} from "@ant-design/icons-vue";
import {dictionaryOpt} from "@/utils/common";
export default defineComponent({
  name: "TopFilter",
  components: {QuestionCircleOutlined, MoreFormList,FormModalBox},
  props:{
    showNuber:{
      type:Number,
      default:4
    }
  },
  emits: ["search", "getRowHeight"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    // const { $EventBus } = useGlobalPropertyHook();
    const formRef = ref();
    const show = ref(false)
    const staffGradeOps = ref([]);
    const locationOps = ref([]);
    const industryLineOps = ref([]);
    const priorityOps = ref([]);
    const completeChannelOps = ref([]);
    const demandStatusOps = ref(dictionaryOpt(['进行中','已完成','已关闭']))

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = {
        staffGrade: formState.staffGrade,
        location: formState.location,
        priority: formState.priority,
        industryLine: formState.industryLine,
        completeChannel: formState.completeChannel,
        condition: formState.condition,
        requirementStatusList: formState.requirementStatusList,
      }
    }
    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType:
              "industryLine,position,city,grade,priority,completeChannel",
          },
        })
        .then((res) => {
          staffGradeOps.value = createSelectOptionData(res.data.grade);
          locationOps.value = createSelectOptionData(res.data.city);
          industryLineOps.value = createSelectOptionData(res.data.industryLine);
          priorityOps.value = createSelectOptionData(res.data.priority);
          completeChannelOps.value = createSelectOptionData(
            res.data.completeChannel
          );
        });
    };
    // 构建select筛选options
    const createSelectOptionData = (data) => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: item.dictCode,
          key: item.dictId,
        };
      });
      return options;
    };
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams()
      emit("search", currentParams);
    };

    // $EventBus.on("resetMatch", () => {
    //   resetForm();
    // });

    const initData = {
      staffGrade: [],
      location: [],
      priority: [],
      industryLine: undefined,
      completeChannel: undefined,
      condition: "",
      requirementStatusList: [],
    }
    const formState = reactive({...initData});


    const handleFormChange = () => {
      updateParams()
      emit("search", currentParams);
    };
    const handleFilter = () => {
      show.value = !show.value
      nextTick(() => {
        emit("getRowHeight")
      })
    };
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'resourceManagement',
        secondMenu: 'demandMatchingDEM',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'staffGrade':
          return staffGradeOps.value
        case 'location':
          return locationOps.value
        case 'industryLine':
          return industryLineOps.value
        case 'priority':
          return priorityOps.value
        case 'completeChannel':
          return completeChannelOps.value
        case 'requirementStatusList':
          return demandStatusOps.value
        default :
          ''  // 如果没
      }
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        formState.positionId = undefined
        formState.lineManager = ''
        formState.reviewTime = []
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getSelectOptions();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    const moreSeachHanlder = () => {
      handleFormChange()
      closeMoreForm()
    }

    return {
      formState,
      show,
      staffGradeOps,
      locationOps,
      priorityOps,
      industryLineOps,
      completeChannelOps,
      formRef,
      handleFormChange,
      resetForm,
      handleFilter,
      updateParams,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder,
      demandStatusOps,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  //height: 88px;
  border-radius: 8px;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px 20px;
  .box{
    height: 160px;
  }
  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
  :deep(.ant-form-item-label > label > .anticon) {
    vertical-align: middle;
    margin-left: 0.2vw;
  }
}
</style>
