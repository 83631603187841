<template>
  <section class="pipeline-list">
    <a-list size="small" bordered :data-source="pipelineList" :pagination="pipelineList.length > 0 ? pagination : false"
            id="pipeline-list-content" class="pipeline-list-content">
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item ant-list-item-no-flex">
          <a-row class="padding-container">
            <a-col :span="22">
              <a-row>
                <a-col :span="10" style="padding-right: 3vw">
                  <p class="first-line pipeline-name">
                    <span class="num" @click="checkPipelineDetails(item)">{{ item.pipelineId }}</span>
                    <Tooltip :title=item.opportunityName>
                      <span class="pipeline-name-text" @click="checkPipelineDetails(item)">{{ item.opportunityName }}</span>
                    </Tooltip>
                    <a-tag color="green" v-show="item.type" class="completetag">
                      {{ item.type === '2'? 'PPL' : '' }}
                    </a-tag>
                    <Tooltip trigger="hover" :title=item.mateNumberTitle>
                      <a-tag class="numbertag">
                        {{ item.currentValidMatchingNum }}
                      </a-tag>
                    </Tooltip>
                    <Tooltip trigger="hover" :title=item.transferOrderNumberTitle>
                      <a-tag class="numbertag">
                        {{ item.currentValidAssignmentNum }}
                      </a-tag>
                    </Tooltip>
                  </p>
                  <ul class="key-msg-list sec-line">
                    <li class="key-msg-item" style="max-width: 33%">
                      <Tooltip title="CST">
                        <img src="@/assets/images/customize-ind.png" class="icon img"/>
                      </Tooltip>
                      <Tooltip :title=item.cst>
                        <span class="key-msg-span">{{ item.cst }}</span>
                      </Tooltip>
                    </li>
                    <li class="key-msg-item" style="max-width: 33%">
                      <Tooltip title="Offering">
                        <img src="@/assets/images/pip-offering.png" class="icon img" />
                      </Tooltip>
                      <Tooltip :title=item.offering>
                        <span class="key-msg-span">{{ item.offering }}</span>
                      </Tooltip>
                    </li>
                    <li class="key-msg-item" style="max-width: 33%">
                      <Tooltip title="SPOC">
                        <img src="@/assets/images/customize-csm.png" class="icon img" />
                      </Tooltip>
                      <Tooltip :title=item.spoc>
                        <span class="key-msg-span">{{ item.spoc }}</span>
                      </Tooltip>
                    </li>
                  </ul>
                </a-col>
                <a-col :span="6">
                  <p class="first-line">
                    <Tooltip :title=item.account>
                      <span style="color: #4d4d4d;font-weight: 600;">Account：{{ item.account }}</span>
                    </Tooltip>
                  </p>
                  <ul class="key-msg-list sec-line" style="color: #3182CE">
                    <li class="key-msg-item">
                      <Tooltip title="Start Date">
                        <ScheduleOutlined class="icon" />
                      </Tooltip>
                      <i class="highest">Start Date {{ item.startDate }}</i>
                    </li>
                  </ul>
                </a-col>
                <a-col :span="7" :offset="1" style="padding-right: 1.25vw">
                  <a-row>
                    <a-col span="15">
                      <div class="key-msg-list sec-line-icon" style="margin-bottom: 12px;">
                        <Tooltip title="Probability %">
                          <img src="@/assets/images/Probability.png" class="icon img" />Probability %:
                        </Tooltip>
                        {{ item.probability }}
                      </div>
                    </a-col>
                    <a-col span="9">
                      <div class="key-msg-list sec-line-icon" style="margin-bottom: 12px;">
                        <Tooltip title="DRB Stage">
                          <MenuOutlined class="icon" />DRB Stage:
                        </Tooltip>
                        {{ item.drbStage }}
                      </div>
                    </a-col>
                    <a-col span="15">
                      <div class="key-msg-list sec-line-icon" style="margin-bottom: 12px;">
                        <Tooltip title="Bidding Status">
                          <img src="@/assets/images/Bidding.png" class="icon img" />BD Status:
                        </Tooltip>
                        {{ item.biddingStatus }}
                      </div>
                    </a-col>
                    <a-col span="9">
                      <div class="key-msg-list sec-line-icon" style="margin-bottom: 12px;">
                        <Tooltip title="CSM">
                          <UserOutlined class="icon" />CSM:
                        </Tooltip>
                        {{ item.csm }}
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="2">
              <div class="add-btn" @click="add(item)" v-show="item.id !== activeRow.id && ![0, 1].includes(nowStep)">
                <SwapOutlined />
              </div>
              <div class="added-btn" @click="removeAdded(item)"
                   v-show="item.id === activeRow.id && ![0, 1].includes(nowStep)">
                <CheckOutlined style="color: white; font-size: 14px; margin-top: 0.2vw;" />
              </div>
            </a-col>
          </a-row>
          <div class="Project-Supplementary-Description">
            <div class="more-info" @click.stop="handleMoreInfoClick(index)">
              <DownCircleFilled class="more-info-icon" :rotate="index == activedItemIndex ? 180 : 0" />
              项目进展情况
            </div>
            <p :class="[
              'suplement',
              index == activedItemIndex ? 'active' : 'un-active',
            ]">
              <span>
                {{ item.projectStatus ? item.projectStatus : "无" }}
              </span>
            </p>
          </div>
        </a-list-item>
      </template>
    </a-list>
    <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo" :nowStep="nowStep"
                   @close-modal="closePipelineModal" />
  </section>
</template>

<script>
import {defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import {
  CheckOutlined,
  DownCircleFilled,
  SwapOutlined,
  UserOutlined,
  ScheduleOutlined,
  MenuOutlined,
} from "@ant-design/icons-vue";
import Tooltip from "@/components/Tooltip.vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";

let queryCache = null;
export default defineComponent({
  name: "PipelineList",
  components: {
    PipelineModal,
    DownCircleFilled,
    SwapOutlined,
    Tooltip,
    UserOutlined,
    CheckOutlined,
    ScheduleOutlined,
    MenuOutlined,
  },
  props: {
    nowStep: {
      type: Number
    },
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const pipelineList = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        getPipelineListData(queryCache);
        // 回到页面顶端
        document.getElementById("pipeline-list-content").parentElement.scrollTop = 0
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        getPipelineListData(queryCache);
      },
    });

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.value.current = 1;
      }
    };

    // 获取列表数据
    const getPipelineListData = (queryData) => {
      queryCache = queryData ? queryData : {
        condition: '',
        subPl: [],
        biddingStatus: [],
        account: [],
        cst: [],
        offering: [],
        salescCsmSpoc: '',
        isFollowUp: undefined
      };
      const data = Object.assign(
          queryCache,
          {
            pageSize: pagination.value.pageSize,
            pageIndex: pagination.value.current,
          }
      );
      $api.pipelineInfo(data).then((res) => {
        pipelineList.value = res.data.resource.map(item => {
          return {
            ...item,
            mateNumberTitle: '已匹配' + item.currentValidMatchingNum + '人',
            transferOrderNumberTitle: '已有调令' + item.currentValidAssignmentNum + '个',
          }
        });
        pagination.value.total = res.data.total;
      });
    };
    // 监听项目补充说明点击
    let activedItemIndex = ref(-1);
    const handleMoreInfoClick = (index) => {
      if (activedItemIndex.value === index) {
        activedItemIndex.value = -1;
      } else {
        activedItemIndex.value = index;
      }
    };
    // reset项目补充说明
    const resetActiveItem = () => {
      activedItemIndex.value = -1;
    };
    // 查看项目详情
    const pipelineVisible = ref(false)
    let optType = 'newMatch'
    const pipelineInfo = ref({})
    const checkPipelineDetails = (details) => {
      pipelineInfo.value = JSON.parse(JSON.stringify(details))
      pipelineVisible.value = true
    };
    const closePipelineModal = () => {
      pipelineInfo.value = {}
      pipelineVisible.value = false
    }
    onMounted(() => {
      resetCurrent();
      document.addEventListener("click", resetActiveItem);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", resetActiveItem);
    });
    // onActivated(() => {
    //   getPipelineListData(queryCache);
    // });

    const activeRow = ref({});

    const add = (data) => {
      activeRow.value = JSON.parse(JSON.stringify(data));
      $EventBus.emit("matchPipeline", data);
    };

    const removeAdded = () => {
      activeRow.value = {};
      $EventBus.emit("matchPipeline", {});
    };
    $EventBus.on("matchPipeline", (data) => {
      activeRow.value = data;
    });
    const resetMatch = () => {
      activeRow.value = {};
      pagination.value.current = 1;
    };

    return {
      pipelineList,
      pagination,
      activedItemIndex,
      resetCurrent,
      getPipelineListData,
      handleMoreInfoClick,
      checkPipelineDetails,
      add,
      removeAdded,
      activeRow,
      resetMatch,
      pipelineVisible,
      optType,
      pipelineInfo,
      closePipelineModal,
    };
  },
})
</script>

<style scoped lang="less">
:deep(.ant-list-empty-text) {
  width: 100%;
  display: table;
}
:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.pipeline-list {
  overflow-y: auto;
  height: 100% !important;

  .pipeline-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 17vw);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    padding: 24px 32px 0;
  }

  .first-line {
    font-size: 18px;
    //color: #1890ff;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pipeline-name {
    display: flex;
    align-items: center;

    .pipeline-name-text {
      cursor: pointer;
      display: inline-block;
      margin-left: 0.41667vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: .9375vw;
      color: #4d4d4d;
      font-weight: 600;
      border-bottom: 1px solid #4d4d4d;
    }

    .completetag {
      font-size: 0.73vw;
      color: #FFFFFF;
      background: #58B99E;
      margin-left: 0.6vw;
    }
    .numbertag {
      margin-right: 0.1667vw;
      padding: 0 0.2458vw;
      font-size: 0.625vw;
      border: 0;
      background: #C1E0FF;
      color: #FFFFFF;
    }
    .ant-tag {
      border-radius: 1vw;
      line-height: inherit;
    }

    .num {
      display: inline-block;
      padding: 0 4px;
      //line-height: 20px;
      color: #3182CE;
      font-weight: 600;
      border: 1px solid #77CAED;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.8vw;
      margin-top: 0.2vw;
    }

    :deep(.ant-tag-blue) {
      color: #3182CE;
      background: #E9F6FE;
      border-color: #E9F6FE;
    }

  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .sec-line-icon{
    font-size: 14px;
    line-height: 22px;
    color: #3182CE;
    margin-bottom: 24px;
    .img {
      width: 17px;
      height: 17px;
      cursor: pointer;
      margin-bottom: 0.2vw;
    }
  }

  .more-info {
    margin: 0 32px 16px;
    display: flex;
    align-items: center;
    width: 100px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .suplement {
    line-height: 32px;
    background: #FAFAFA;
    padding: 0 32px;
    margin-bottom: 0;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: 0s;
      border-top: 1px solid #CCCCCC;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      .key-msg-span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 0.05vw;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }

      .img {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .added-btn {
    background-color: #1890ff;
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: calc(45px / 2);
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }
}

:deep(.ant-list-bordered) {
  border: 0px;
}

:deep(.ant-pagination) {
  justify-content: flex-end;
}
</style>
