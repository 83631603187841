<template>
  <section class="top-filter">
    <FilterLayout
        @doReset="resetForm"
        @doSearch="handleFormChange"
        ref="filterLayoutRef"
    >
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            layout="vertical"
            :model="formState"
        >
          <a-form-item label="需求名称" class="rms-form-item" name="condition">
            <a-input
                v-model:value="formState.condition"
                placeholder="Name/ID"
                allowClear
            />
          </a-form-item>
          <a-form-item
              label="客户名称"
              name="clientName"
              class="rms-form-item"
          >
            <SelectWithAll
                v-model:value="formState.clientName"
                :options="requirementNameOps"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
            >
            </SelectWithAll>
          </a-form-item>
          <a-form-item label="CSM" class="rms-form-item" name="csm">
            <a-input
                v-model:value="formState.csm"
                placeholder="Name/GGID"
                allowClear
            />
          </a-form-item>
          <a-form-item
              label="部门层级"
              class="rms-form-item"
              name="departmentLevel"
          >
            <SelectWithAll
                v-model:value="formState.departmentLevel"
                :options="departmentLevelOps"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
            >
            </SelectWithAll>
          </a-form-item>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right" v-if="nowStep === 2">
          <Tooltip title="新建需求">
            <a-button class="ml12" type="primary" @click="addCustomizeClick"> <PlusOutlined /></a-button>
          </Tooltip>
        </div>
      </template>
    </FilterLayout>
  </section>
</template>

<script>
import {PlusOutlined} from "@ant-design/icons-vue";
//import {useGlobalPropertyHook} from "@/hooks/common";
import {nextTick, reactive, ref, watch} from "vue";
import {dictionaryOpt} from "@/utils/common";
import {useGlobalPropertyHook} from "@/hooks/common";
import Tooltip from "@/components/Tooltip.vue";
export default {
  name: "TopFilterCustomize",
  components: {PlusOutlined,Tooltip},
  emits: ["search", "getRowHeight", "customizeCreate"],
  props: {
    nowStep:{
      type: Number,
      default: 0,
    },
    customizeOps: {
      type: Object,
      default: () => ({
        requirementNameOps: [],
        departmentLevelOps: [],
      }),
    }
  },
  setup(props, { emit }) {
    //const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const formRef = ref();
    const show = ref(false)
    const requirementNameOps = ref([]);
    const departmentLevelOps = ref([]);

    const formState = reactive({
      departmentLevel: [],
      csm: "",
      condition: "",
      clientName: [],
    });

    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = {
        condition: formState.condition,
        clientName: formState.clientName,
        csm: formState.csm,
        departmentLevel: formState.departmentLevel,
      }
    }
    const demandInfo = ref({});
    //自定义需求新建
    $EventBus.on("matchCustomize", (data) => {
      demandInfo.value = data;
    });

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      emit("search", currentParams);
    };

    const handleFormChange = () => {
      updateParams()
      emit("search", currentParams);
    };

    const handleFilter = () => {
      show.value = !show.value
      nextTick(() => {
        emit("getRowHeight")
      })
    };

    const addCustomizeClick = () => {
      emit("customizeCreate",{},'add');
    }

    watch(
        () => props.customizeOps,
        (val) => {
          if(val) {
            requirementNameOps.value = dictionaryOpt(props.customizeOps.requirementNameOps)
            departmentLevelOps.value = dictionaryOpt(props.customizeOps.departmentLevelOps)
          }
        },
        {deep:true}
    )

    return {
      formState,
      show,
      requirementNameOps,
      departmentLevelOps,
      formRef,
      handleFormChange,
      resetForm,
      handleFilter,
      updateParams,
      addCustomizeClick,
      demandInfo,
    };
  },
}
</script>

<style scoped lang="less">
.top-filter {
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 20px;
  .box{
    height: 160px;
  }
  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
  :deep(.ant-form-item-label > label > .anticon) {
    vertical-align: middle;
    margin-left: 0.2vw;
  }
}
</style>
