<template>
  <div class="matcher" ref="matcherRef">
    <div class="matcher-header">
      <CardTitle size="large" title="匹配器" />
    </div>
    <div class="list scroll-wrap">
      <a-steps direction="vertical" :current="current">

        <a-step title="添加顾问">
          <template #description>
            <div name="selectConsultant1" v-show="current == 0">
              <div :class="consultantInfo.length === 0 ? 'description2 descriptionCloor' : 'description2'">
                <div v-if="consultantInfo.length === 0" style="text-align: center;margin-top: 2.5vw;color: #B3B3B3">
                  <div v-if="demandInfo.completeChannel !== 'TA_Team'">请在左侧列表勾选需要添加的顾问 <div>（可多选）</div>
                  </div>
                  <div v-else>请在左侧列表勾选需要添加的顾问
                    <div>（TA 渠道的需求只允许匹配一个顾问）</div>
                  </div>
                </div>
                <a-list :bordered="false" size="small" :data-source="consultantInfo" class="Consultant-list" v-else>
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <span class="removeConsultant">
                        <div class="circle"></div>
                        {{ item.fullName }}
                        {{ ' / ' }}
                        {{ item.psGroup }}
                        {{ ' / ' }}
                        {{ item.workLocation }}
                      </span>
                      <span @click="removeConsultant(item)"><img src="@/assets/images/close-circle.png"
                          class="img1" /></span>
                      <!--                      <CloseOutlined @click="removeConsultant(item)" />-->
                    </a-list-item>
                  </template>
                </a-list>
              </div>
              <a-button type="primary" class="confirm" @click="confirmConsultants"
                :disabled="consultantInfo.length === 0">
                下一步
              </a-button>
            </div>

            <div name="selectConsultant2" v-show="current > 0">
              <div :class="consultantInfo.length === 0 ? 'description2 descriptionCloor' : 'description2'">
                <a-list :bordered="false" size="small" :data-source="consultantInfo" class="Consultant-list2">
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <span class="removeConsultant">
                        <div class="circle"></div>
                        {{ item.fullName }}
                      </span>
                    </a-list-item>
                  </template>
                </a-list>
              </div>
            </div>
          </template>
        </a-step>

        <a-step title="选择渠道">
          <template #description>
            <div v-show="current == 1 ? true : false">
              <div style="margin:12px 0px">
                <a-select style="width:100%" v-model:value="demandValue" placeholder="请选择渠道" @change="changeDemandType">
                  <a-select-option v-for="item in demandOptions" :key="item.title" :title="item.title"
                    :value="item.value">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </div>
              <a-button type="primary" class="confirm" @click="selectDemandButton(1)" :disabled="demandValue === null">
                下一步
              </a-button>
              <a-button type="primary" class="confirm" @click="selectDemandButton(-1)" :disabled="demandValue === null">
                上一步
              </a-button>
            </div>
            <div v-show="current > 1">
              <div class="descriptionSelect">
                <a-select :disabled="true" style="width:100%" v-model:value="demandValue" placeholder="请选择渠道"
                  @change="changeDemandType">
                  <a-select-option v-for="item in demandOptions" :key="item.title" :title="item.title"
                    :value="item.value">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </div>
            </div>

          </template>
        </a-step>

        <a-step title="添加需求">
          <template #description>
            <div class="demand1" v-show="current == 2">
              <a-card
                :class="(!demandInfo.demandName && !demandInfo.opportunityName) ? 'description descriptionCloor' : 'description'"
                @click="showModal(demandInfo)">
                <div v-if="!demandInfo.demandName && !demandInfo.opportunityName"
                  style="text-align: center;margin-top: 3vw;color: #B3B3B3;margin-bottom: 3vw">
                  请在左侧列表勾选需要添加的需求
                </div>
                <div v-else class="demandInfo1" :style="`background: ${demand ? '#E9F6FE' : '#FAFAFA'}`">
                  <div v-if="demandValue === 1">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.demandName>
                          <span class="demandName1">{{ demandInfo.demandName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24">
                        <span class="projectName1">{{ demandInfo.projectName }}</span>
                      </a-col>
                      <a-col :span="8" style="margin-top: 0.6vw;color: #999999;">
                        <Tooltip title="工作地点">
                          <EnvironmentOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.workLocation }}
                      </a-col>
                      <a-col :span="4" style="margin-top: 0.6vw">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="12" style="margin-top: 0.6vw;color: #999999;">
                        <Tooltip title="完成渠道">
                          <TeamOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.completeChannel }}
                      </a-col>
                      <a-col :span="4" style="color: #999999;">
                        <Tooltip title="人员级别">
                          <img src="@/assets/iconfont/level.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.staffGrade }}
                      </a-col>
                      <a-col :span="3">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="6" style="color: #999999;">
                        <Tooltip title="预计时长">
                          <ClockCircleOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.useStaffLength }}
                      </a-col>
                      <a-col :span="3">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="8" style="color: #999999;">
                        <Tooltip title="到岗时间">
                          <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.expectArrivalTime }}
                      </a-col>
                    </a-row>
                  </div>
                  <div v-if="demandValue === 2">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.opportunityName>
                          <span class="demandName1">{{ demandInfo.opportunityName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24" style="margin-bottom: 0.2vw">
                        <Tooltip :title=demandInfo.account>
                          <span class="projectName1">{{ demandInfo.account }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="CST">
                          <img src="@/assets/images/customize-ind.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.cst }}
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="Offering">
                          <img src="@/assets/images/pip-offering.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.offering }}
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="SPOC">
                          <img src="@/assets/images/customize-csm.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.spoc }}
                      </a-col>
                    </a-row>
                  </div>
                  <div v-if="demandValue === 3">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.demandName>
                          <span class="demandName1">{{ demandInfo.demandName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24">
                        <span class="projectName1">{{ demandInfo.customerName }}</span>
                      </a-col>
                      <a-col :span="24" style="margin-top: 0.5vw; color: #999999;">
                        <Tooltip title="行业线">
                          <img src="@/assets/images/customize-ind.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.industryLine }}
                      </a-col>
                      <a-col :span="24" style="margin-top: 0.5vw;color: #999999;">
                        <Tooltip title="CSM">
                          <img src="@/assets/images/customize-csm.png" style="margin-top: -0.2vw;" class="icon img" />
                        </Tooltip>
                        <span>{{ demandInfo.csm }}</span>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-card>
              <a-button type="primary" class="confirm" @click="confirmNeeds(demandInfo, 1, consultantInfo)"
                :disabled="!demandInfo.demandName && !demandInfo.opportunityName" v-show="current == 2">下一步
              </a-button>
              <a-button type="primary" class="confirm" @click="confirmNeeds(demandInfo, -1, consultantInfo)"
                v-show="current == 2">上一步
              </a-button>

            </div>
            <div class="demand1 otherCurrent"
              v-show="current != 2 && (demandInfo.demandName || demandInfo.opportunityName)">
              <a-card>
                <div class="demandInfo1" :style="`background: ${demand ? '#E9F6FE' : '#FAFAFA'}`">
                  <div v-if="demandValue === 1">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.demandName>
                          <span class="demandName1">{{ demandInfo.demandName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24">
                        <span class="projectName1">{{ demandInfo.projectName }}</span>
                      </a-col>
                      <a-col :span="8" style="margin-top: 0.6vw;color: #999999;">
                        <Tooltip title="工作地点">
                          <EnvironmentOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.workLocation }}
                      </a-col>
                      <a-col :span="4" style="margin-top: 0.6vw">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="12" style="margin-top: 0.6vw;color: #999999;">
                        <Tooltip title="完成渠道">
                          <TeamOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.completeChannel }}
                      </a-col>
                      <a-col :span="4" style="color: #999999;">
                        <Tooltip title="人员级别">
                          <img src="@/assets/iconfont/level.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.staffGrade }}
                      </a-col>
                      <a-col :span="3">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="6" style="color: #999999;">
                        <Tooltip title="预计时长">
                          <ClockCircleOutlined class="icon" />
                        </Tooltip>
                        {{ demandInfo.useStaffLength }}
                      </a-col>
                      <a-col :span="3">
                        <span class="segmentation"></span>
                      </a-col>
                      <a-col :span="8" style="color: #999999;">
                        <Tooltip title="到岗时间">
                          <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.expectArrivalTime }}
                      </a-col>
                    </a-row>
                  </div>
                  <div v-if="demandValue === 2">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.opportunityName>
                          <span class="demandName1">{{ demandInfo.opportunityName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24" style="margin-bottom: 0.2vw">
                        <Tooltip :title=demandInfo.account>
                          <span class="projectName1">{{ demandInfo.account }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="CST">
                          <img src="@/assets/images/customize-ind.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.cst }}
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="Offering">
                          <img src="@/assets/images/pip-offering.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.offering }}
                      </a-col>
                      <a-col :span="24" style="color: #999999;">
                        <Tooltip title="SPOC">
                          <img src="@/assets/images/customize-csm.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.spoc }}
                      </a-col>
                    </a-row>
                  </div>
                  <div v-if="demandValue === 3">
                    <div class="iconClose" v-show="demand">
                      <span @click="close()"><img src="@/assets/images/close-circle.png" class="img1" /></span>
                    </div>
                    <a-row style="padding: 0.6vw">
                      <a-col :span="24">
                        <Tooltip :title=demandInfo.demandName>
                          <span class="demandName1">{{ demandInfo.demandName }}</span>
                        </Tooltip>
                      </a-col>
                      <a-col :span="24">
                        <span class="projectName1">{{ demandInfo.customerName }}</span>
                      </a-col>
                      <a-col :span="24" style="margin-top: 0.5vw; color: #999999;">
                        <Tooltip title="行业线">
                          <img src="@/assets/images/customize-ind.png" class="icon img" />
                        </Tooltip>
                        {{ demandInfo.industryLine }}
                      </a-col>
                      <a-col :span="24" style="margin-top: 0.5vw;color: #999999;">
                        <Tooltip title="CSM">
                          <img src="@/assets/images/customize-csm.png" style="margin-top: -0.2vw;" class="icon img" />
                        </Tooltip>
                        <span>{{ demandInfo.csm }}</span>
                      </a-col>
                    </a-row>
                  </div>
                </div>

              </a-card>
            </div>
          </template>
        </a-step>

        <a-step title="确认匹配信息">
          <template #description>
            <div name="finishMatch" v-show="current == 3" class="finishMatch">
              <div class="showSelectedPM" v-if="!canFinsh">
                <div class="pmFourtitle">PM<span>*</span></div>
                <a-select :disabled="true" style="width:100%" v-model:value="pmValue" show-search placeholder="暂无PM"
                  :show-arrow="false">
                  <a-select-option :title="pm.fullName + '/' + pm.email" v-for="pm in pmOption"
                    :value="pm.fullName + '/' + pm.email" :key="pm.id">
                    {{ pm.fullName + "/" + pm.email }}
                  </a-select-option>
                </a-select>
              </div>
              <a-button type="primary" :disabled="canFinsh" class="confirm" @click="stepFourFinish(1)"
                :loading="loadingButton">创建匹配
              </a-button>
              <a-button type="primary" class="confirm" @click="stepFourFinish(-1)">上一步
              </a-button>
            </div>
          </template>
        </a-step>

      </a-steps>

      <a-modal :getContainer="() => $refs.matcherRef" :visible="noLoginvisible" @cancel="noLoginCancel" title="填写调令"
        :width="'20vw'" centered>
        <div>
          <div style="margin-bottom: 10px">请选择操作流程</div>
          <div>
            <a-radio-group v-model:value="noLoginValue">
              <a-radio style="display: block" :value="1">常规流程</a-radio>
              <a-radio style="display: block" :value="2">免登录链接流程</a-radio>
            </a-radio-group>
          </div>
        </div>
        <template #footer>
          <div class="footerBtn">
            <a-button @click="noLoginCancel">取消</a-button>
            <a-button @click="noLoginConfirm" type="primary">确认</a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  <DemandInfo ref="demandInfoRef"></DemandInfo>
  <CustomizeInfo ref="customizeInfoRef" :nowStep="0" />
  <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo" :nowStep="0"
    @close-modal="closePipelineModal" />
</template>
<script>
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import DemandInfo from "./DemandInfo.vue";
import { useRouter } from "vue-router";
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons-vue";
import CardTitle from "@/components/CardTitle.vue";
import Tooltip from "@/components/Tooltip.vue";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
import ModalBox from '@/components/ModalBox'
export default defineComponent({
  name: "Matcher",
  components: {
    PipelineModal,
    CustomizeInfo,
    ClockCircleOutlined,
    EnvironmentOutlined,
    Tooltip,
    CardTitle,
    DemandInfo,
    TeamOutlined,
    ModalBox,
  },
  props: {
    isCustomizeCreate: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["reset-match", "stepChange", "getCustomizeData", "handerSaveCustomize", "getPipelineData"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const router = useRouter();
    const demandInfo = ref({});
    const demandInfoRef = ref();
    const customizeInfoRef = ref()
    const addedConsultants = ref([]);
    //  匹配器里顾问的数组
    let consultantInfo = ref([]);
    // demand为true的时候 -- 匹配器的添加需求里可以删掉当前需求，为false的时候则在其他步骤，不可删掉当前需求
    let demand = ref(true);
    // 进行到了从0开始第几步
    let current = ref(0);
    // finishMatch 为 1 的时候是最后是否提交，即完成了 需求/人员 匹配
    let finishMatch = ref(0);
    let disableButton = ref(false);
    let loadingButton = ref(false);

    // 顾问
    $EventBus.on("matchConsultant", (matcherConsultantList) => {
      consultantInfo.value = matcherConsultantList.value;
    });

    let demandType = ref()
    // 北森新建
    $EventBus.on("matchDemand", (data) => {
      demandType.value = '北森'
      demandInfo.value = data;
    });

    //自定义需求新建
    $EventBus.on("matchCustomize", (data) => {
      demandType.value = '自定义'
      demandInfo.value = data;
    });

    //Pipeline需求
    $EventBus.on("matchPipeline", (data) => {
      demandType.value = 'PipeLine'
      demandInfo.value = JSON.parse(JSON.stringify(data));
    });

    const pipelineVisible = ref(false)
    let optType = 'newMatch'
    const pipelineInfo = ref({})
    const closePipelineModal = () => {
      pipelineInfo.value = {}
      pipelineVisible.value = false
    }

    const showModal = (demandInfo) => {
      if (demandInfo.demandName != undefined && demandValue.value === 1 && !props.isCustomizeCreate) {
        demandInfoRef.value.btnVisible = false;
        demandInfoRef.value.showModal(demandInfo);
      } else if (demandInfo.demandName != undefined && demandValue.value === 3 && !props.isCustomizeCreate) {
        customizeInfoRef.value.btnVisible = false;
        customizeInfoRef.value.showModal(demandInfo);
      } else if (demandInfo.opportunityName != undefined && demandValue.value === 2 && !props.isCustomizeCreate) {
        pipelineInfo.value = demandInfo
        pipelineVisible.value = true
      }
    };

    const confirmNeeds = (demandInfo, direction, consultantInfo) => {

      if (direction == -1) {
        // 清空需求
        current.value = 1;
        canFinsh.value = true
        emit("stepChange", 1, demandValue.value)
      } else {
        if (demandInfo.demandName == null && demandInfo.opportunityName == null) {
          message.warning("请添加需求");
        } else {
          //下一步时保存自定义需求
          if (demandValue.value === 3) {
            emit("handerSaveCustomize", demandInfo)
          }
          emit("stepChange", 3, demandValue.value, demandInfo, consultantInfo)
          current.value = 3;
          demand.value = false;
        }
      }
    };

    const close = () => {
      $EventBus.emit("matchDemand", "");
      $EventBus.emit("matchCustomize", "");
      $EventBus.emit("matchPipeline", "");
    };

    const removeConsultant = (consultant) => {
      consultantInfo.value.splice(
        consultantInfo.value.findIndex((item) => item.staffId === consultant.staffId), 1
      )
      $EventBus.emit("deleteConsult", consultantInfo.value);

    };

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')

    const handleReturn = () => {
      content.value = '当前需求已经匹配完成，请返回第三步重新选择需求！'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
    }

    const handleOk = () => {
      stepFourFinish(-1, true)
    }



    // 匹配器添加顾问的下一步
    const confirmConsultants = () => {
      if (consultantInfo.value.length == 0) {
        message.warning("请选择顾问");
      } else {
        // 为true时候仍处于添加顾问页面时候不能加顾问
        disableButton.value = true;
        $EventBus.emit("disableButton", disableButton.value);
        current.value = 1;
        finishMatch.value = 0;
        demandValue.value = 1
        // 1是第二步，1是北森需求
        emit("stepChange", 1, 1);
      }
    };

    const noLoginvisible = ref(false)
    const noLoginConfirm = () => {
      loadingButton.value = true;
      const data = createDemandInfo()
      if (noLoginValue.value === 1) {
        $api.newPreMatch(data)
          .then(() => {
            noLoginvisible.value = false
            current.value = 3;
            canFinsh.value = true
            router.push("/matchingList");
            message.success("创建预匹配成功");
            loadingButton.value = false;
            consultantInfo.value = [];
            demandInfo.value = {};
            emit("reset-match");
          })
          .catch((error) => {
            loadingButton.value = false;
            if (error.msg == '该需求已匹配完成，请重新选择需求！') {
              noLoginvisible.value = false
              handleReturn()
            }
          });
      } else {
        $api
          .createInputLink(data)
          .then(() => {
            noLoginvisible.value = false
            current.value = 3;
            canFinsh.value = true
            router.push("/matchingList");
            message.success("创建预匹配成功");
            loadingButton.value = false;
            consultantInfo.value = [];
            demandInfo.value = {};
            noLoginValue.value = 1;
            emit("reset-match");
          })
          .catch((error) => {
            loadingButton.value = false;
            if (error.msg == '该需求已匹配完成，请重新选择需求！') {
              noLoginvisible.value = false
              handleReturn()
            }
          });
      }
    }
    const noLoginCancel = () => {
      noLoginvisible.value = false
      noLoginValue.value = 1
    }
    const noLoginValue = ref(1);

    const demandValue = ref(1)
    const demandOptions = ref([
      { title: '北森需求', value: 1 },
      { title: 'Pipeline需求', value: 2 },
      { title: '自定义需求', value: 3 },
    ])

    // 选择渠道的按钮函数 ，  -1 是上一步 , 1 是下一步
    const selectDemandButton = (type) => {
      if (type === 1) {
        if (consultantInfo.value.length > 5 && [1, 3].includes(demandValue.value)) {
          message.error("该渠道需求最多选择5个顾问");
          return
        }
        emit("stepChange", 2, demandValue.value, [], consultantInfo.value);
        current.value = 2
        demand.value = true;
      } else {
        emit("stepChange", 0, demandValue.value);
        disableButton.value = false;
        $EventBus.emit("disableButton", disableButton.value);
        current.value = 0
        demand.value = false;
      }
    }

    const changeDemandType = (value) => {
      emit("stepChange", 1, value)
      if (value === 3) {
        emit("getCustomizeData", value)
      } else if (value === 2) {
        emit("getPipelineData")
      }
    }
    // true的时候不能点击创建匹配
    const canFinsh = ref(true)
    let finshObj = {}
    let pmValue = ref()
    let pmOption = ref([])
    let isContainPMValue = ref()
    // isContainPM 是false的时候则第四步选了不发送给PM
    $EventBus.on("finishFourStep", (data) => {
      canFinsh.value = false
      isContainPMValue.value = data.isContainPM
      finshObj = data.value
      if (data.isContainPM) {
        pmValue.value = data.pm.pm
        pmOption.value = data.pm.option
      } else {
        pmValue.value = null
        pmOption.value = []
      }
    });
    // 第二个参数是匹配已完成的话调用上一步，然后传入true的话会刷新第三步的列表
    const stepFourFinish = (step, isRefresh = false) => {
      if (step === -1) {
        current.value = 2
        demand.value = true
        canFinsh.value = true
        finshObj = {}
        emit("stepChange", 2, demandValue.value, demandInfo.value, consultantInfo.value, isRefresh)
        $EventBus.emit("clearFinish")
      } else {
        if (isContainPMValue.value == false) {
          finishWithOutPM()
        } else {
          finishWithPM()
        }

      }
    }

    // 创建匹配组装入参，接受一个参数，默认为true即选择了PM
    const createDemandInfo = (flag = true) => {
      const data = {
        ...finshObj,
        projectDemand: demandInfo.value
      }
      if (demandType.value == 'PipeLine') {
        data.pipelineId = demandInfo.value.id
      } else {
        data.pipelineId = ''
      }
      if (flag) {
        data.isSendPm = '1'
      } else {
        data.isSendPm = '0'
      }
      delete data.projectDemand.createTime
      delete data.projectDemand.operationTime
      return data
    }

    const finishWithOutPM = () => {
      let data = createDemandInfo(false)
      $api.newPreMatch(data)
        .then(() => {
          current.value = 3;
          canFinsh.value = true
          router.push("/matchingList");
          message.success("创建预匹配成功");
          loadingButton.value = false;
          consultantInfo.value = [];
          demandInfo.value = {};
          emit("reset-match");
        })
        .catch((error) => {
          loadingButton.value = false;
          if (error.msg == '该需求已匹配完成，请重新选择需求！') {
            noLoginvisible.value = false
            handleReturn()
          }
        });
    }

    const finishWithPM = () => {
      if (consultantInfo.value.length > 1) {
        loadingButton.value = true;
        const data = createDemandInfo()
        $api.newPreMatch(data)
          .then(() => {
            current.value = 3;
            canFinsh.value = true
            router.push("/matchingList");
            message.success("创建预匹配成功");
            loadingButton.value = false;
            consultantInfo.value = [];
            demandInfo.value = {};
            emit("reset-match");
          })
          .catch((error) => {
            loadingButton.value = false;
            if (error.msg == '该需求已匹配完成，请重新选择需求！') {
              noLoginvisible.value = false
              handleReturn()
            }
          });
      } else {
        noLoginvisible.value = true
      }
    }

    return {
      addedConsultants,
      demandInfo,
      demandInfoRef,
      consultantInfo,
      current,
      demand,
      finishMatch,
      disableButton,
      loadingButton,
      showModal,
      close,
      confirmNeeds,
      removeConsultant,
      confirmConsultants,
      noLoginvisible,
      noLoginConfirm,
      noLoginValue,
      noLoginCancel,
      demandValue,
      demandOptions,
      selectDemandButton,
      changeDemandType,
      stepFourFinish,
      canFinsh,
      customizeInfoRef,
      pmValue,
      pmOption,
      pipelineVisible,
      optType,
      pipelineInfo,
      closePipelineModal,
      handleOk,
      modelValue,
      content,
      showIconCom,
      isContainPMValue
    };
  },
});
</script>

<style lang="less" scoped>
::v-deep {
  .ant-card-body {
    padding: 10px;
    //border: none;
  }

  .ant-empty-image svg {
    display: none;
  }

  .ant-empty-description {
    display: none;
  }

  .ant-empty-image {
    display: none;
  }

  .ant-steps-item-title {
    //font-family: bold;
    font-size: 20px;
  }
}

:deep(.ant-list-empty-text) {
  height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  width: 100%;
  display: table;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.scroll-wrap {
  flex: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    height: 10px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.matcher {
  height: 100%;
  overflow: auto;

  .matcher-header {
    margin: 1vw;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px;
    padding: 20px 0 0 0px;
    font-size: 25px;
    color: #1890ff;
  }

  .list {
    height: 87%;
    padding: 0 10px;

    .demand1 {
      .span {
        display: inline-block;
        padding-right: 20px;
      }

      .iconClose {
        position: absolute;
        right: 0.2vw;
        top: 0.1vw;

        .img1 {
          width: 1.25vw;
          height: 1.25vw;
        }
      }

      .demandInfo1 {
        .icon {
          font-size: 0.83vw;
        }

        .img {
          width: 0.9vw;
          height: 0.9vw;
        }

        .segmentation {
          content: "";
          display: inline-block;
          height: 0.88542vw;
          width: 1px;
          background-color: #359eff;
          margin: 0.15vw 0.52083vw 0;
        }

        .demandName1 {
          color: #3182CE;
          font-size: 0.93vw;
          font-weight: 500;
          width: 17vw;
          cursor: pointer;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .projectName1 {
          font-size: 0.83vw;
          color: #4D4D4D;
          font-weight: 500;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .span1 {
          color: #999999;
          font-size: 0.73vw;
          font-weight: 400;
        }
      }
    }

    .otherCurrent {
      border: 2px dashed #CCCCCC;
    }

    .demand2 {
      cursor: pointer;

      .demandOk {
        font-size: 15px;
        border: none;
      }
    }

    .description {
      cursor: pointer;
      border: 2px dashed #CCCCCC;
      //height: 8.5vw;
      //min-height: 8.5vw;

      ::v-deep .ant-list-empty-text {
        height: 20px;
      }
    }

    .descriptionSelect {
      cursor: pointer;
      border: 2px dashed #CCCCCC;
      padding: 10px;
    }

    .descriptionCloor {
      background: #FAFAFA;
    }

    .description2 {
      cursor: pointer;
      border: 2px dashed #CCCCCC;
      height: 8vw;
      min-height: 8vw;
      overflow-y: auto;

      ::v-deep .ant-list-empty-text {
        height: 20px;
      }
    }

    .Consultant-list {
      :deep(.ant-list-item) {
        background: #E9F6FE;
        margin: 0.41667vw 0.83333vw;
      }
    }

    .Consultant-list2 {
      :deep(.ant-list-item) {
        background: #FAFAFA;
        margin: 0.41667vw 0.83333vw;
      }
    }


    .returnToEdit {
      font-size: 20px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5px;
    }

    .confirm {
      margin: 5px 0 0 10px;
      float: right;
    }

    .cancel {
      margin: 5px 0 0 50px;
      float: right;
    }
  }


  .circle {
    width: 10px;
    height: 10px;
    background-color: #8fc959;
    border-radius: 50%;
    margin: 0 8px 0 0;
    display: inline-block;
  }

  .img1 {
    width: 1.25vw;
    height: 1.25vw;
  }

  .finishMatch {
    margin-left: 18px;

    :deep(.ant-form-item) {
      margin-bottom: 0;
    }

    .showSelectedPM {
      margin-bottom: 5px;

      .pmFourtitle {
        span {
          color: red
        }
      }
    }
  }

  :deep(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title) {
    color: #3182CE;
  }

  :deep(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title) {
    color: #999999;
  }
}

:deep(.ant-modal) {
  .ant-modal-footer {
    padding: 0 24px;
    height: 64px;
    line-height: 64px;
    border-top: 1px solid #ebebeb !important;
  }
}
</style>
