<template>
  <a-drawer class="demandModel" v-if="visible" v-model:visible="visible" :title="title ? title : '需求详情'" width="38.5vw"
            @close="cancel" :getContainer="getRefsAff">
    <div id="demand">
      <div class="cusTitle" v-if="isConsult">
        <div class="demandName">
          <div class="circle"></div>
          <span style="max-width: 90%;font-weight: 600;">{{ demandInfo.demandName }}</span>
        </div>
        <div style="display: flex;min-width: 30%;">
          <div style="margin-right: 1vw">
            <div class="info1">已有匹配</div>
            <div class="info2">
              <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ demandInfo.mateNumber }}</span>
              <span style="font-size: 0.83vw">人</span>
            </div>
          </div>
          <div class="info3"></div>
          <div style="margin-left: 1vw">
            <div class="info1">已有调令</div>
            <div class="info2">
              <span style="font-size: 1.25vw;margin-right: 0.2vw;">{{ demandInfo.transferOrderNumber }}</span>
              <span style="font-size: 0.83vw">项</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CardTitle class="card"> 需求信息 </CardTitle>
      </div>
      <div>
        <a-row class="demandInfoRow">
          <a-col :span="24">
            <div>
              需求编号:<p class="text">{{ demandInfo.demandNumber }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              需求名称:<p class="text">{{ demandInfo.demandName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              客户服务经理(CSM):<p class="text">{{ demandInfo.csm }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              客户名称:<p class="text">{{ demandInfo.customerName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              行业线111:<p class="text">{{ demandInfo.industryLine }}</p>
            </div>
          </a-col>
          <a-col :span="24">
            <div>
              详细客户名称:<p class="text">{{
                demandInfo.customerNameDetail
              }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              末级需求部门:<p class="text">{{ demandInfo.demandDeptName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              部门层级:<p class="text">{{ demandInfo.deptHierarchy }}</p>
            </div>
          </a-col>
          <a-col :span="24">
            <hr style="height: 1px; border: none; border-top: 1px solid #f2f2f2" />
          </a-col>
          <a-col :span="12" style="margin-top: 0.6vw">
            <div>
              岗位名称:<p class="text">{{ demandInfo.demandPosition }}</p>
            </div>
          </a-col>
          <a-col :span="12" style="margin-top: 0.6vw">
            <div>
              项目确定性:<p class="text">{{ demandInfo.projectCertainty }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              工作地点:<p class="text">{{ demandInfo.workLocation }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              人员级别:<p class="text">{{ demandInfo.staffGrade }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              期望到岗时间:<p class="text">{{
                demandInfo.expectArrivalTime
              }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              需求性质:<p class="text">{{ demandInfo.demandNature }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              预计用人时长:<p class="text">{{demandInfo.useStaffLength?demandInfo.useStaffLength+'个月':'' }}</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <hr style="height: 1px; border: none; border-top: 1px solid #f2f2f2;margin-bottom: 0.5vw" />
      <template v-if="type === 'Process'">
        <div>
          <CardTitle class="card"> 操作信息 </CardTitle>
        </div>
        <a-row class="demandInfoRow">
          <a-col :span="12">
            <div>
              创建人:<p class="text">{{ demandInfo.createOperator }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              创建时间:<p class="text">{{ demandInfo.createTime || convertDateFormat(demandInfo.createTimeData) }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              更新人:<p class="text">{{ demandInfo.operator }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              更新时间:<p class="text">{{ demandInfo.operationTime || convertDateFormat(demandInfo.operationTimeData) }}</p>
            </div>
          </a-col>
        </a-row>
      </template>
      <template v-if="type === 'List'">
        <div>
          <CardTitle class="card">顾问人员信息</CardTitle>
        </div>
        <a-row class="demandInfoRow">
          <a-col :span="12">
            <div>
              顾问:<p class="text">{{ demandInfo.fullName }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              邮箱:<p class="text">{{ demandInfo.email }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              职级:<p class="text">{{ demandInfo.psGroup }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              角色:<p class="text">{{ demandInfo.position }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              工作地点:<p class="text">{{ demandInfo.staffWorkLocation }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              人员状态:<p class="text">{{ demandInfo.status }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              service Line:<p class="text">{{ demandInfo.serviceLine }}</p>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              sub service Line:<p class="text">{{ demandInfo.subServiceLine }}</p>
            </div>
          </a-col>
          <a-col :span="24">
            <div>
              PM:<p class="text">{{ demandInfo.pmName + ' (' + demandInfo.pmEmail+ ')'}}</p>
            </div>
          </a-col>

          <a-col :span="12">
            <div>
              ARVE Assumption %:
              <div class="arveChange" v-show="arveValueChange">
                <p class="text arveText">{{ demandInfo.arveAssumption }}</p>
                <FormOutlined class="changeIcon" v-if="canChangeARVE" @click="changeArve('num', demandInfo.arveAssumption)" />
              </div>
              <div class="positionAnother" v-show="!arveValueChange">
                <a-form ref="formRef" :model="arveFormValue">
                  <a-form-item name="value" :rules="[
                      {
                        validator: () => validateArve(arveFormValue.value),
                        trigger: ['change', 'blur'],
                      }]">
                    <a-input autocomplete='off' style="width:70px" v-model:value="arveFormValue.value"
                             placeholder="请输入" />
                    <CheckOutlined class="changeIcon" @click="confirmArve('num')" />
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              ARVE Assumption Start Date:
              <div class="arveChange" v-show="arveTimeChange">
                <p class="text arveText">{{ convertDateFormat(demandInfo.arveAssumptionStartDate) }}</p>
                <FormOutlined class="changeIcon" v-if="canChangeARVE"
                              @click="changeArve('date', convertDateFormat(demandInfo.arveAssumptionStartDate))" />
              </div>
              <div class="positionAnother" v-show="!arveTimeChange">
                <a-form ref="timeRef" :model="arveFormTime">
                  <a-form-item name="value" :rules="[
                      {
                        validator: () => validateTime(arveFormTime.value),
                        trigger: ['change', 'blur'],
                      }]">
                    <a-date-picker class="item" style="width: 150px" v-model:value="arveFormTime.value" type="date"
                                   placeholder="选择日期" valueFormat="YYYY-MM-DD"/>
                    <CheckOutlined class="changeIcon" @click="confirmArve('date')" />
                  </a-form-item>
                </a-form>

              </div>

            </div>
          </a-col>
        </a-row>
      </template>

    </div>
    <div class="bottom-btn" v-if="isHaveAdd">
      <a-button @click="cancel" class="cancelBtn">取消</a-button>
      <a-button @click="handleOk(demandInfo)" type="primary" class="addEmpBtn" v-if="btnVisible && nowStep === 2">添加至匹配器
      </a-button>
    </div>
    <div class="bottom-btn" v-else>
      <a-button @click="cancel" class="cancelBtn">关闭</a-button>
    </div>
  </a-drawer>
  <ModalBox :class="iconRed ? 'icon-red' : ''" v-model="modelValue" :ok="handleOkModal" :content="content"
            :showIconCom="showIconCom" :footer="footer" />
</template>

<script>
import CardTitle from "@/components/CardTitle.vue";
import { ref,watch,computed} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import ModalBox from "@/components/ModalBox.vue";
import { message } from "ant-design-vue";
import { FormOutlined, CheckOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
export default {
  name: "CustomizeInfo",
  components: { ModalBox, CardTitle,FormOutlined, CheckOutlined },
  props: {
    title: String,
    isHaveAdd: {
      type: Boolean,
      default: true,
    },
    nowStep: {
      type: Number
    },
    consultObj: Array,
    // 来区分是匹配流程中(Process)的弹窗还是匹配列表(List)的弹窗，匹配列表的弹窗有额外的ARVE数据
    type: {
      type: String,
      default: 'Process'
    },
    // canChangeARVE 为true 可以弹窗内修改ARVE
    canChangeARVE: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refreshTable'],
  setup(props,{emit}) {
    const store = useStore();
    const isConsult = computed(() => {
      const roles = store.state.app.user.roles;
      console.log('roles',roles)
      return !roles.includes('4')
      // return !(roles.indexOf("4") !== -1)
    });
    const visible = ref(false);
    const demandInfo = ref({});
    const { $EventBus } = useGlobalPropertyHook();
    const btnVisible = ref(true);
    let myRef = ref();
    let showMore = ref();
    const showModal = (data) => {
      demandInfo.value = data;
      visible.value = true;
      console.log("isConsult",isConsult.value)
    };
    const { $api } = useGlobalPropertyHook();
    const handleOk = async (data) => {
      let obj = {
        userId: props.consultObj.map((item) => { return item.staffId }),
        demandId: data.id,
      }
      let res = await $api.isConsultInDemand(obj)
      if (res.msg !== '成功') {
        modalData.value = data
        content.value = `顾问${res.data.join('、')}已匹配过该需求，不可重复匹配`
        showIconCom.value = 'ExclamationCircleOutlined'
        footer.value = true
        modelValue.value = true
        iconRed.value = true
        return
      }
      const params = {
        demandId: data.id,
      };
      $api.isDemandMatched(params).then((res) => {
        if (res.data) {
          modalData.value = data
          content.value = '本需求PM已有选定人员并填写完调令，是否继续本条匹配？'
          showIconCom.value = 'ExclamationCircleOutlined'
          footer.value = false
          modelValue.value = true
        } else {
          $EventBus.emit("matchCustomize", data);
          visible.value = false;
        }
      });
    };
    const iconRed = ref(false)
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const footer = ref()
    const modalData = ref()
    const handleOkModal = () => {
      if (modalData.value) {
        $EventBus.emit("matchCustomize", modalData.value);
        visible.value = false;
      }
    }

    const moreInfo = () => {
      showMore.value = !showMore.value;
    };

    const cancel = () => {
      visible.value = false;
      showMore.value = false
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const convertDateFormat = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const arveValueChange = ref(true)
    const arveTimeChange = ref(true)
    const formRef = ref()
    const timeRef = ref()
    const arveFormValue = ref({})
    const arveFormTime = ref({})

    watch(()=>visible.value,(val)=>{
      if(val == true){
        arveValueChange.value = true
        arveTimeChange.value = true
      }
    },{immediate:true})

    // type 是num 是值 是date 是日期
    const changeArve = (type, value) => {
      if (type == 'num') {
        arveFormValue.value = { value }
        arveValueChange.value = !arveValueChange.value
      } else {
        arveFormTime.value = { value }
        arveTimeChange.value = !arveTimeChange.value
      }
    }

    const validateArve = (value) => {
      const isValidNumber = (input) => {
        const regex = /^(0$|[1-9][0-9]?|100)$/;
        return regex.test(input);
      }
      let flag = isValidNumber(value)
      if (!flag) {
        return Promise.reject('请输入0-100之间的数字')
      } else {
        return Promise.resolve()
      }
    }
    const validateTime = (value) => {
      if(value == null){
        return Promise.reject('ARVE Assumption Start Date 为必填项')
      } else {
        return Promise.resolve()
      }
    }

    const confirmArve = (type) => {
      if (type == 'num') {
        formRef.value.validate().then(async () => {
          let arr = []
          arr[0] = {
            "preMatchingId": demandInfo.value.preMatchingId,
            "arveAssumption": arveFormValue.value.value,
            "arveAssumptionStartDate": convertDateFormat(demandInfo.value.arveAssumptionStartDate)
          }
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([demandInfo.value.preMatchingId])
          arveValueChange.value = !arveValueChange.value
          let obj = {
            "arveAssumption": res.data[0].arveAssumption,
            "arveAssumptionStartDate": res.data[0].arveAssumptionStartDate
          }
          demandInfo.value = Object.assign(demandInfo.value,obj)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption % 填写的不符合规则");
        })
      } else {
        timeRef.value.validate().then(async () => {
          let arr = []
          arr[0] = {
            "preMatchingId": demandInfo.value.preMatchingId,
            "arveAssumption": demandInfo.value.arveAssumption,
            "arveAssumptionStartDate": arveFormTime.value.value
          }
          await $api.batchChangeArve(arr)
          let res = await $api.afterBatchChangeUpdate([demandInfo.value.preMatchingId])
          arveTimeChange.value = !arveTimeChange.value
          let obj = {
            "arveAssumption": res.data[0].arveAssumption,
            "arveAssumptionStartDate": res.data[0].arveAssumptionStartDate
          }
          demandInfo.value = Object.assign(demandInfo.value,obj)
          emit('refreshTable',res.data)
        }).catch(() => {
          message.error("ARVE Assumption % 填写的不符合规则");
        })
      }
    }

    return {
      myRef,
      showMore,
      visible,
      demandInfo,
      moreInfo,
      showModal,
      handleOk,
      cancel,
      getRefsAff,
      btnVisible,
      convertDateFormat,
      modelValue,
      content,
      showIconCom,
      iconRed,
      footer,
      handleOkModal,

      changeArve,
      arveValueChange,
      arveTimeChange,
      arveFormValue,
      arveFormTime,
      validateArve,
      validateTime,
      confirmArve,
      formRef,
      timeRef,
      isConsult
    };
  },
}
</script>

<style lang="less" scoped>
.footerBtn {
  text-align: center;
  margin: 5px;

  .cancelBtn,
  .addEmpBtn {
    margin: 10px 15px;
  }
}

#demand {
  position: relative;
  background: #ffffff;
  margin: 1.25vw 0px 10px 1.25vw;

  .cusTitle {
    border: 1px solid #CCCCCC99;
    margin: 0 1vw 1vw 0;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .demandName {
      font-size: 1vw;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      align-items: center;
      max-width: 70%;
    }
    .circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color:#F9BB1C;
      margin-right: 15px;
    }

    .info1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.22em;
      color: #999999;
    }

    .info2 {
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
    }

    .info3 {
      height: 40px;
      border: 1px solid #CCCCCC
    }
  }

  .card {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;

    &::before {
      top: 0.1vw;
    }
  }

  .demandInfoRow {
    margin-top: 1vw;
    color: rgba(77, 77, 77, 0.6);
    white-space: pre;
    margin-bottom: 10px !important;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .text {
    //margin-left: 16px;
    color: #4D4D4D;
    white-space: initial;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0.2vw 0 0.83vw 0.2vw;
  }

  .projectSupplement {
    width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .info {
    overflow: hidden;
    //width: 1150px;
    width: 100%;
  }

  .hideInfo {
    height: 50px;
  }

  .moreInfoBtn {
    color: #3182CE;
    text-align: center;
    width: 30px;
    cursor: pointer;
    margin-left: 1vw;
  }

  .moreInfoP {
    //line-height: 170%;

    width: 85%;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .moreInfoPMore {
    width: 85%;
    overflow: auto;
  }

  p {
    margin-bottom: 0
  }

  .moreInfo {
    display: flex;
    color: #4D4D4D;
  }
}
.arveChange {
  display: flex;
  align-items: center;

  .arveText {
    margin: 0.2vw 0.5vw 0vw 0.2vw !important;
  }

  .changeIcon {
    display: block;
    margin-top: 0.2vw;
    cursor: pointer;
    color: rgb(49, 130, 206)
  }


  .ant-form {
    padding: 0.2vw !important;

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
.positionAnother {
  .arveText {
    margin: 0.2vw 0.5vw 0vw 0.2vw !important;
  }

  .changeIcon {
    display: block;
    margin-top: 0.2vw;
    margin-left: 10px;
    cursor: pointer;
    color: rgb(49, 130, 206)
  }

  .ant-form {
    padding: 0.2vw !important;
    position: relative;

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
</style>
<style>
.demandModel .ant-modal-body {
  padding: 0 !important;
}
</style>
