<template>
  <div class="TopFilterC">
    <section>
      <div>
        <FilterLayout @doReset="resetForm" @doSearch="queryDoSearch" ref="filterLayoutRef" @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible" :isShowMoreFlag="isShowMoreList">
          <template #customform>
            <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
              <template v-for="(item, index) in queryList" :key="index">
                <template v-if="index <= 3">
                  <a-form-item :label="item.code" name="name" class="rms-form-item"
                    :class="item.inputDataType === 'assignmentType' || 'availability' ? 'explainIcon' : ''">
                    <template v-if="item.type === '2'">
                      <a-select v-model:value="formState[item.inputDataType]" :options="getOptions(item.inputDataType)"
                        placeholder="请选择" allowClear show-search>
                      </a-select>
                    </template>
                    <template v-if="item.type === '3'">
                      <a-input v-model:value="formState[item.inputDataType]" placeholder="Name/GGID" allowClear
                        autocomplete="off" />
                    </template>
                    <template v-if="item.type === '4'">
                      <a-radio-group v-model:value="formState[item.inputDataType]" button-style="solid" class="btnRadio">
                        <a-radio-button :value="item.value" v-for="(item, index) in getOptions(item.inputDataType)"
                          :key="index">{{ item.label }}</a-radio-button>
                      </a-radio-group>
                    </template>
                    <template v-if="item.type === '5'">
                      <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                        showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)">
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type === '6'">
                      <a-range-picker v-model:value="formState.dateValueList" :placeholder="['Start date', 'End date']"
                        format="YYYY-MM-DD" @change="handleDateChange" />
                    </template>
                  </a-form-item>
                </template>
              </template>
              <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm"
                @moreFormSearch="moreSeachHanlder">
                <template v-for="(item, index) in queryList" :key="index">
                  <template v-if="index > 3">
                    <a-form-item name="name" class="rms-form-item">
                      <template v-slot:label
                        v-if="item.type === '2' && (item.inputDataType === 'assignmentType' || item.inputDataType === 'availability')">
                        <span>{{ item.code }}</span>
                        <a-popover placement="topLeft">
                          <QuestionCircleOutlined :style="{ color: '#3182ce' }" />
                          <template #content>
                            <a-table
                              :columns="item.inputDataType === 'assignmentType' ? assignmentColumns : availabilityColumns"
                              :data-source="item.inputDataType === 'assignmentType' ? assignmentData : availabilityData"
                              :pagination="false"></a-table>
                          </template>
                        </a-popover>
                      </template>
                      <template v-slot:label v-else>
                        <span>{{ item.code }}</span>
                      </template>
                      <template v-if="item.type === '2'">
                        <a-select v-model:value="formState[item.inputDataType]" :options="getOptions(item.inputDataType)"
                          placeholder="请选择" allowClear show-search>
                        </a-select>
                      </template>
                      <template v-if="item.type === '3'">
                        <a-input v-model:value="formState[item.inputDataType]" placeholder="Name/GGID" allowClear
                          autocomplete="off" />
                      </template>
                      <template v-if="item.type === '4'">
                        <a-radio-group v-model:value="formState[item.inputDataType]" button-style="solid"
                          class="btnRadio">
                          <a-radio-button :value="item.value" v-for="(item, index) in getOptions(item.inputDataType)"
                            :key="index">{{ item.label }}</a-radio-button>
                        </a-radio-group>
                      </template>
                      <template v-if="item.type === '5'">
                        <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                          showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)">
                        </SelectWithAll>
                      </template>
                      <template v-if="item.type === '6'">
                        <a-range-picker v-model:value="formState.dateValueList" :placeholder="['Start date', 'End date']"
                          format="YYYY-MM-DD" @change="handleDateChange" />
                      </template>
                    </a-form-item>
                  </template>
                </template>
              </MoreFormList>
            </a-form>
          </template>
          <template #operationBtns>
          </template>
        </FilterLayout>
      </div>
    </section>
    <FormModalBox v-model:visible="isShowFormSortBox" :checkList="checkList" @closeModal="closeModal"
      @reset="queryFormList"></FormModalBox>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOpt, dictionaryOptions } from "@/utils/common.js";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { handleJtpFilter } from "@/utils/common";
import { cloneDeep, debounce } from "lodash";
import { getValueAndOps } from "@/utils/orgSelectData";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import { extraListHandler } from '../hooks/hooks'

const availabilityColumns = [
  {
    title: 'Availability',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '字段解释',
    dataIndex: 'explain',
    key: 'explain'
  },
]

const availabilityData = [
  { name: 'Partial Bench', explain: '人员当天的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)小时数相加大于0小于等于4小时' },
  { name: 'Unavailable', explain: '人员当天的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)小时数相加大于4小于等于8小时' },
  { name: 'Fully-bench', explain: '人员当天没有正在进行中的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)' },
]

const assignmentColumns = [
  {
    title: '调令类型',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '字段解释',
    dataIndex: 'explain',
    key: 'explain'
  },
]

const assignmentData = [
  { name: 'Internal', explain: '行业线选择为others的调令' },
  { name: 'Billable', explain: '行业线选择为非others的调令' },
]

export default defineComponent({
  name: "TopFilterC",
  components: {
    QuestionCircleOutlined,
    MoreFormList,
    FormModalBox
  },
  emits: ["search"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const {
      moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
      showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList, queryFormList
    } = extraListHandler()

    const skillNameOps = ref([]);
    const staffGradeOps = ref([]);
    const assignmentTypeOps = ref([]);
    const availabilityOps = ref([]);
    const positionStatusOps = ref([]);
    const taskTypeOps = ref([]);
    const taskTypeMap = ref({});
    const formRef = ref(null);
    const statusOps = ref([]);
    const roleOps = ref([]);
    let withJtpGradeOps;
    let withoutJtpGradeOps;

    const orgOpsObj = reactive({
      orgTwoOps: [],
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: []
    })

    const formState = reactive({
      condition: '',
      orgFourOneOneList: [],
      orgThreeTwoList: [],
      statusList: [],
      skillName: [],
      gradeList: [],
      taskTypeList: [],
      jobStatus: ["1"],
      withJTP: '0',
      roleList: [],
      orgTwoList: [],
      orgFiveOneList: [],
      dateValueList: [],
      onBoardBegin: '',
      onBoardEnd: '',
      assignmentType: undefined,
      availability: undefined
    })

    let currentState = ref()

    const handleDateChange = () => {
      formState.onBoardBegin = formState.dateValueList[0]
      formState.onBoardEnd = formState.dateValueList[1]
    }

    const radioOptions = ref([
      { label: 'With JTP', value: '1' },
      { label: 'Without JTP', value: '0' },
    ]);

    const getSelectOptions = () => {
      //数据字典
      $api.getDictionaryList({
        params: {
          dictType: "grade,skillName,assignmentType,availability,staffStatus,jobStatus,role",
        },
      }).then((res) => {
        roleOps.value = dictionaryOptions(res.data.role)
        withJtpGradeOps = cloneDeep(createSelectOptionData(res.data.grade))
        withoutJtpGradeOps = handleJtpFilter(withJtpGradeOps)
        if (withoutJtpGradeOps && withJtpGradeOps) {
          staffGradeOps.value = formState.withJTP === '0' ? withoutJtpGradeOps : withJtpGradeOps
        }
        skillNameOps.value = createSelectOptionData(res.data.skillName, 'special');
        assignmentTypeOps.value = createSelectOptionData(res.data.assignmentType);
        availabilityOps.value = createSelectOptionData(res.data.availability);
        statusOps.value = createSelectOptionData(res.data.staffStatus);
        positionStatusOps.value = createSelectOptionData(res.data.jobStatus.filter((item)=>{return item.dictValue !== '离职'}))
      });
    };

    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgTwoList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgTwoOps = dictionaryOpt(orgTwoList);
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      })
    };

    //查询
    const doSearch = () => {
      currentState.value = cloneDeep(formState);
      emit("search", currentState.value)
    };

    const queryDoSearch = () => {
      doSearch()
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }

    //options value
    const createSelectOptionData = (data, type = 'true') => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: type == 'special' ? item.dictValue : item.dictCode,
          key: item.dictId,
        };
      });
      return options;
    };

    //重置
    const resetForm = () => {
      Object.assign(formState, {
        condition: '',
        orgFourOneOneList: [],
        orgThreeTwoList: [],
        statusList: [],
        skillName: [],
        gradeList: [],
        taskTypeList: [],
        jobStatus: ["1"],
        withJTP: '0',
        roleList: [],
        orgTwoList: [],
        orgFiveOneList: [],
        dateValueList: [],
        onBoardBegin: '',
        onBoardEnd: '',
        assignmentType: undefined,
        availability: undefined
      });
      doSearch();
      getOrgList();
    };

    const getTaskType = () => {
      $api.getWorkTaskComboBox().then((res) => {
        taskTypeOps.value = (res.data?.taskType || []).map(
          ({ dictCode, dictValue, dictRemark }) => {
            taskTypeMap.value[dictCode] = {
              color: dictRemark,
              value: dictCode,
              label: dictValue,
            };
            return {
              value: dictCode,
              label: dictValue,
            };
          }
        );
      });
    };


    const getOptions = (data) => {
      switch (data) {
        case 'orgTwoList':
          return orgOpsObj.orgTwoOps
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'jobStatus':
          return positionStatusOps.value
        case 'gradeList':
          return staffGradeOps.value
        case 'skillName':
          return skillNameOps.value
        case 'roleList':
          return roleOps.value
        case 'availability':
          return availabilityOps.value
        case 'statusList':
          return statusOps.value
        case 'assignmentType':
          return assignmentTypeOps.value
        case 'withJTP':
          return radioOptions.value
        case 'taskTypeList':
          return taskTypeOps.value
        default:
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data) => {
      switch (data) {
        case 'orgTwoList':
          return handleOrgChange('orgTwo')
        case 'orgThreeTwoList':
          return handleOrgChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return handleOrgChange('orgFourOneOne')
        case 'orgFiveOneList':
          return handleOrgChange('orgFiveOne')
        default:
          ''  // 如果没
      }
    }, 500)


    onMounted(() => {
      getSelectOptions();
      getOrgList();
      getTaskType();
      getOrgRelatedList();
      queryFormList()
    });

    const handleOrgChange = (type) => {
      const formOrgData = {
        orgTwoList: formState.orgTwoList,
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const { orgsValue, orgOpsList } = getValueAndOps(orgAllList.value, formOrgData, orgOpsObj, type)
      Object.assign(formState, orgsValue)
      Object.assign(orgOpsObj, orgOpsList)
    };

    const orgAllList = ref([])
    const getOrgRelatedList = () => {

      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }

    watch(
      () => formState.withJTP,
      (val) => {
        if (withoutJtpGradeOps && withJtpGradeOps) staffGradeOps.value = val === '0' ? withoutJtpGradeOps : withJtpGradeOps
        if (val === '0' && formState.gradeList.length) {
          formState.gradeList = handleJtpFilter(formState.gradeList)
        }
      },
      { immediate: true, deep: true }
    );

    return {
      queryDoSearch,
      formState,
      skillNameOps,
      staffGradeOps,
      positionStatusOps,
      assignmentTypeOps,
      availabilityOps,
      formRef,
      resetForm,
      doSearch,
      statusOps,
      taskTypeOps,
      taskTypeMap,
      handleOrgChange,
      availabilityColumns,
      availabilityData,
      assignmentColumns,
      assignmentData,
      radioOptions,
      handleDateChange,
      roleOps,
      orgOpsObj,
      orgAllList,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.TopFilterC {
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.btnRadio {
  width: 100%;
  display: flex;

  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
}

.assignment-icon {
  background: rgba(24, 144, 255, 0.6);
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  padding: 2px 5px;
  margin-left: 5px;
}

.availability-icon {
  background: rgba(24, 144, 255, 0.6);
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
  color: #505050;
  padding: 2px 8px;
}

.task-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 4px;
}

.explainIcon {
  :deep(.ant-form-item-label > label > .anticon) {
    vertical-align: baseline;
  }
}

:deep(.dropdown) {
  .ant-dropdown-menu-item {
    color: #3182CE;

    &.ant-dropdown-menu-item-disabled {
      color: #ccc;
    }

    &:hover {
      background: #E9F6FE;
    }

    span.ant-dropdown-menu-title-content {
      display: flex;
      height: 100%;
      line-height: 36px;
    }

    .anticon {
      display: inline-block;
      width: 24px;
    }
  }
}

:deep(.icon-font) {
  display: inline-block;
  color: #3182CE;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
</style>
